import React from 'react';
import './Education.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import Educationslides from '../education/educationslider/Educationslides'
import Educationslides from '../education/educationslider/Educationslides';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import Whatsappchat from '../component/Whatsappchat';


const Education = () => {
  return (
    <>
    <Helmet>
  <title>Education Sector of Arunachal</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/logo.png" />
  {/* <link rel="icon" href="%PUBLIC_URL%/images/logo.png" /> */}
</Helmet>


    
 <hr className="separator" />
    <Educationslides/>

<br/>
    <hr className="separator" />
<h2 className="latest-post" style={{color: "green"}}> <b> Education Sector of A.P. </b></h2>
<hr className="separator" />



<hr className="separator" />
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Education Institutes in Arunachal</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Govt. Schools in Arunchal Pradesh </Link></li>
  <li><Link className={"link-styles"} to="">Private Schools in Arunchal Pradesh  </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}

</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Top Coaching Institutes in Arunachal </h3>
<ol>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}

</ol>



</div>
</div>
<br />
<hr className="separator" />
<br />



                                {/* Accord Institutes */}

                                <div className="content-box">
<h3 className='topic' style={{color: "red", fontSize: "22px",}}>Educational Institutes in Arunachal:</h3>

<Accordion>

                    {/* Education: Schools and Colleges */}

{/* Govt Schools */}

<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. Govt. Schools (Sr. Sec.)</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/education">JNV </Link></li>
</ol>

<h3 className='topic'>Schools in ICR </h3>
<ol>
 
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Ziro </h3>
<ol>

{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Pasighat </h3>
<ol>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Aalo </h3>
<ol>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Daporijo </h3>
<ol>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>


<br />
<h3 className='topic'>Schools in Tezu </h3>
<br />
</div>




</Accordion.Body>
</Accordion.Item>

<br />

{/* Govt. Schools */}

<Accordion.Item eventKey="20">
<Accordion.Header> <b className='accord-head'> 2. Private Schools  </b></Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/education">RK Mission </Link></li> 
<li><Link className={"link-styles"} to="/education">VKV</Link></li> 

</ol>

<h3 className='topic'>Schools in ICR </h3>
<ol>
<li><Link className={"link-styles"} to="/education">Royal International School </Link></li> 
<li><Link className={"link-styles"} to="/education">Delhi Public School </Link></li> 
<li><Link className={"link-styles"} to="/education">Him International School, Jollang </Link></li> 
<li><Link className={"link-styles"} to="/education">Lekhi Public School, Lekhi </Link></li>
<li><Link className={"link-styles"} to="/education">Kingcup School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Alphabet Public School, Nirjuli  </Link></li> 
<li><Link className={"link-styles"} to="/education">Bunny's Fantasy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Vivekananda Central School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Little Rose School, Naharlagun  </Link></li> 
<li><Link className={"link-styles"} to="/education">Little Star School, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">JNK School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">New Galaxy School, Nirjuli </Link></li> 
<li><Link className={"link-styles"} to="/education">St. Thomas School, Naharlagun </Link></li>  
<li><Link className={"link-styles"} to="/education">Garden Dew School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Vidyamandir, Itanagar  </Link></li> 
<li><Link className={"link-styles"} to="/education">Little Flower School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Holy Cross School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">St. Alphonsa Public School, Naharlagun </Link></li> 
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Ziro </h3>
<ol>
<li><Link className={"link-styles"} to="/education">Frontier School, Ziro </Link></li> 
<li><Link className={"link-styles"} to="/education">Padi Lailang Memorial Scool, Ziro  </Link></li> 
<li><Link className={"link-styles"} to="/education">Blue Pine School, Ziro </Link></li> 
<li><Link className={"link-styles"} to="/education">Sharda Mission (Girls only), </Link></li> 
<li><Link className={"link-styles"} to="/education">Bumer Memorial School, Ziro </Link></li> 
<li><Link className={"link-styles"} to="/education">Nime Ashram School, Ziro </Link></li> 
<li><Link className={"link-styles"} to="/education">Eklavya Public School, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Don Bosco School </Link></li> 
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Pasighat </h3>
<ol>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Aalo </h3>
<ol>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>

<h3 className='topic'>Schools in Daporijo </h3>
<ol>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>


<br />
<h3 className='topic'>Schools in Tezu </h3>
<br />
</div>


</Accordion.Body>
</Accordion.Item>

<br />



  {/* University */}

<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. Universities</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">

<ol>
<li><Link className={"link-styles"} to="/education">Rajiv Gandhi University, Doimukh  </Link></li> 
<li><Link className={"link-styles"} to="/education">Himalayan University, Jollang </Link></li> 
<li><Link className={"link-styles"} to="/education">Arunachal University of Studies </Link></li> 
<li><Link className={"link-styles"} to="/education">Arunodaya University </Link></li> 
<li><Link className={"link-styles"} to="/education">North Eastern Regional Institute of Science and Technology (NERIST) </Link></li> 

</ol>
</div>
</Accordion.Body>
</Accordion.Item>





<br />
          {/* Colleges */}

<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>4. Colleges</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>

<li><Link className={"link-styles"} to="/education">DNGC, Itanagar  </Link></li> 
<li><Link className={"link-styles"} to="/education">JNC, Pasighat </Link></li> 
<li><Link className={"link-styles"} to="/education">Don Bosco College, Jollang </Link></li> 
<li><Link className={"link-styles"} to="/education">Saint Claret College, Ziro </Link></li> 
<li><Link className={"link-styles"} to="/education">Arunachal Law College, Lekhi </Link></li> 
<li><Link className={"link-styles"} to="/education">Donyi Polo </Link></li> 
<li><Link className={"link-styles"} to="/education">Jarbom Gamlin Govt. Law College, Jote </Link></li> 
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>





<br />


{/* Computer */}

<Accordion.Item eventKey="7">
<Accordion.Header><b className='accord-head'>5. Computer Institutes </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/Institutes">Institutes  </Link></li> 
<li><Link className={"link-styles"} to="/education"> </Link></li> 
<li><Link className={"link-styles"} to="/education">    </Link></li> 
<li><Link className={"link-styles"} to="/education">  </Link></li> 
<li><Link className={"link-styles"} to="/education">  </Link></li> 
<li><Link className={"link-styles"} to="/education"></Link></li> 
</ol>
</div>
</Accordion.Body>
</Accordion.Item>


</Accordion>

  </div>




<br />

  <hr className="separator" />

  <br />  

                                {/* Accord Institutes : Copy to Job updates */}

                                <div className="content-box">
<h3 className='topic' style={{color: "red", fontSize: "22px",}}>Coaching Institutes in Arunachal:</h3>

<Accordion>
<Accordion.Item eventKey="3">
<Accordion.Header><b className='accord-head'>1. UPSC CSE / APPSC</b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><a href="https://gumbaja.com/">Subject-wise Study Materials</a></li>
<li><Link to="/education">ALS </Link></li> 
<li><Link className={"links"} to="/education">Success Point </Link></li> 

<li><Link className={"links"} to="/education">IBE Coaching Center, Itanagar </Link></li> 
<li><Link className={"links"} to="/education">Jom's Coaching Institute (TGT-PGT), Itanagar </Link></li> 
<li><Link className={"links"} to="/education">Photon IAS, ICR </Link></li> 
<li><Link className={"links"} to="/education">CSM Academy, Itanagar </Link></li>
<li><Link className={"links"} to="/education">Edunachal, Itanagar & Naharlagun </Link></li>
<br />
<li><Link className={"link-styles"} to="/education">L.M. IAS Institute </Link></li> 
<li><Link className={"link-styles"} to="/education">Naukri Maker </Link></li>
<li><Link className={"link-styles"} to="/education">Roy Academy, ICR </Link></li>

<li><Link className={"link-styles"} to="/education">A.T. Institute Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">The AIMER'S Institute (AE/JE) </Link></li>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="4">
<Accordion.Header><b className='accord-head'>2. APSSB</b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>    
<li><Link className={"link-styles"} to="/education">Nesol, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Catalyst Classes, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">The Bridge Academy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">GOPRO educare, Itanagar and Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">BH Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Edumiles Academy </Link></li>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> */}
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="5">
<Accordion.Header><b className='accord-head'>3. Medical Entrance </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/education">Spectrum Coaching Center, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">The Pie Academy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Nimbus Tutorials, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Gurukul Institute, Itanagar </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">Lalan's Integrated Academy </Link></li> 
<li><Link className={"link-styles"} to="/education">Scholar Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Roy Academy, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">Shankalp Vidyapeeth, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Edunachal Academy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Mahendra's Institutes, Itanagar </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">IBE Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">INSIGHT GURU, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">IMPACT INSTITUTE, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Catalyst Classes, Itanagar and Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">Noble Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Paul's Learning, Itanagar </Link></li> 
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>
<li><Link className={"link-styles"} to="/education"> </Link></li>
<li><Link className={"link-styles"} to="/education"> </Link></li>
<li><Link className={"link-styles"} to="/education"> </Link></li> */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>



<br />


<Accordion.Item eventKey="6">
<Accordion.Header><b className='accord-head'>3. Libraries cum Study Room </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/education">Aspirants Library, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">Study Nest, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">24x7 Self-Study Library, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Study Nest, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">24x7 SELF-STUDY LIBRARY, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">STP Study Room cum Library, Naharlagun </Link></li> 
</ol>
</div>
</Accordion.Body>
</Accordion.Item>

<br />



</Accordion>

  </div>


  <br />

<hr className="separator"/>

<br />

                 {/* Issues in Education Sector */}
<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>Important Notes:</h3>

    <Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />

</Accordion>

  </div>



<hr className="separator" />



    {/* <hr className="separator" /> */}
    <WhatsAppShareButton/>
    <hr className="separator" />
    

    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>
    <Outlet />
    </>
  )
}

export default Education
