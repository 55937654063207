import React from 'react';
import './Common.css';

function Call() {
  const phoneNumber = '+917005369425'; // Replace with your actual phone number

  return (

    <div className="autobox">

    <div className='call '>
      {phoneNumber && (
        <a href={`tel:${phoneNumber}`}>Call us</a>
        // <a href={`tel:${phoneNumber}`}>Call me</a>
      )}
    </div>

    </div>
  );
}

export default Call;
