import React, { useState } from 'react';
import './Politics.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
// import Healthformat2slides from './Healthformat2slides';
// import Healthformat2slides from '../health/Healthformat2slides';
import Govtslider from './politicsslider/Govtslider';




// npm install react-helmet

function Mpandmlaofarunachal({ }) {
return (

<>


<Helmet>
<title>MPs and MLAs of Arunachal Pradesh</title>
<meta name="description" content="MPs and MLAs of Arunachal Pradesh: There are Three MPs and Sixty MLAs in Arunachal." />
<meta property="og:title" content="MPs and MLAs of Arunachal Pradesh" />
<meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>




<hr className="separator"/>

<Govtslider/>

<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>MPs and MLAs of Arunachal Pradesh</b></h3>

<hr className="separator" />




<div className="content-box">
There are 3 MPs and 60 MLAs in Arunachal Pradesh. 
<br /> <br />

<h3 className='topic'>MPs of Arunachal</h3>
    

<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. Nabam Rebia (Rajya Sabha)..(Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2. Tapir Gao (Arunachal East)</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. Kiren Rijiju (Arunachal West) </b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
  <ol>
    <li>Minister of Parliamentary Affairs.</li>
    <li>Minister of Minority Affairs.</li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>



</Accordion>

</div>

<hr className="separator" />

<br />

      {/* List of MLAs */}
<div className="content-box">
<Accordion>

<h3 className='topic'>CM and Ministers of Arunachal</h3>
<br />
<Accordion.Item eventKey="61">
<Accordion.Header> <b className='accord-head'>(1). 3. Mukto: Pema Khandu  </b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
<ol>
  <li>Hon'ble Chief Minister (in Chair). </li>
  <li>All Departments not Assigned to Deputy Chief Minister and anyCabinet Minister.</li>
</ol>
</div>
</Accordion.Body> 
</Accordion.Item>

<br />


<Accordion.Item eventKey="62">
<Accordion.Header> <b className='accord-head'>(2). 46. Chowkham: Chowna Mein   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Hon'ble Deputy Chief Minister.</li>
    <li>Finance, Planning & Investment.</li>
    <li>Power & Non Conventional Energy Resources</li>
    <li>Tax & Excise.</li>
    <li>State Lotteries.</li>
    <li>Economics & Statistics.</li>
  </ol>

</div>
</Accordion.Body>
</Accordion.Item>


<br />



<Accordion.Item eventKey="63">
<Accordion.Header> <b className='accord-head'>(3). 35. Pangin: Ojing Tasing </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Rural Development and PanchayatiRaj.</li>
    <li>Co-operation.</li>
    <li>Transport (Secretariat Transport, Transport,Inland Water Transport, StateTransport Services).</li>
  </ol>

</div>
</Accordion.Body>
</Accordion.Item>



<br />





<Accordion.Item eventKey="64">
<Accordion.Header> <b className='accord-head'>(4). 30. Aalo-East: Kento Jini </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Law, Legislative & Justice </li>
    <li>Social Justice</li>
    <li>Empowerment and Tribal Affairs</li>
    <li>Sports & Youth Affairs</li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>

<br />



<Accordion.Item eventKey="65">
<Accordion.Header> <b className='accord-head'>(5). 18. Palin: Balo Raja   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
<ol>
  <li>Land Management.</li>
  <li>Civil Aviation. </li>
  <li>Urban Affairs (Urban Development, Town Planning,Urban Local Bodies, Housing).</li>
</ol>
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="66">
<Accordion.Header> <b className='accord-head'>(6). 11. Seppa-West: Mama Natung</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Home and Inter State Border Affairs.</li>
    <li>Public Health Engineering & WaterSupply.</li>
    <li>Department of Indigenous Affairs.</li>
  </ol>

</div>
</Accordion.Body>
</Accordion.Item>



<br />




<Accordion.Item eventKey="67">
<Accordion.Header> <b className='accord-head'>(7). 45. Hayuliang: Smt. Dasanglu Pul  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Women and Child Development</li>
    <li>Science & Technology</li>
    <li>Cultural Affairs (Art & Culture, Research, Gazeteers)</li>
  </ol>

</div>
</Accordion.Body>
</Accordion.Item>


<br />





<Accordion.Item eventKey="68">
<Accordion.Header> <b className='accord-head'>(8). 33. Mechukha: P. D. Sona  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
  <li>Education</li>
  <li>Rural Works</li>
  <li>Parliamentary Affairs</li>
  <li>Tourism</li>
  <li>Libraries</li>
  </ol>

</div>
</Accordion.Body>
</Accordion.Item>


<br />




<Accordion.Item eventKey="69">
<Accordion.Header> <b className='accord-head'>(9). 58. Kanubari: Gabriel D. Wangsu  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Agriculture </li>
    <li>Horticulture</li>
    <li>Animal Husbandry & Veterinary</li>
    <li>Dairy Development</li>
    <li>Fisheries</li>
    <li>Food & Civil Supplies</li>
    <li>Legal Metrology</li>
    <li>Consumer Affairs</li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>


<br />




<Accordion.Item eventKey="70">
<Accordion.Header> <b className='accord-head'>(10). 54. Namsang: Wangki Lowang   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic' style={{color: "green"}}>Portfolios:</h3>
  <ol>
    <li>Environment & Forest.</li>
    <li>Geology, Mining & Minerals.</li>
    <li>Development of Tirap, Changlang & Longding.</li>
  </ol>

</div>
</Accordion.Body>
</Accordion.Item>

<br />

<hr className="separator" />

                      {/* MLAs */}
<h3 className='topic'>MLAs of Arunachal</h3>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'>1. Lumla: Smt.Tsering Lhamu (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
1.
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2. Tawang: Namge Tsering </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />
<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. Mukto: Pema Khandu  </b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
Hon'ble Chief Minister (in Chair). <br />
All Departments not Assigned to Deputy Chief Minister and anyCabinet Minister.
<br /><br />
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="3">
<Accordion.Header><b className='accord-head'>4. Dirang: Phurpa Tsering </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="4">
<Accordion.Header><b className='accord-head'>5. Kalaktang: Tsetan Chombey </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />
<Accordion.Item eventKey="5">
<Accordion.Header><b className='accord-head'>6. Thrizino-Buragaon: Tenzin Nima Glow </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="6">
<Accordion.Header> <b className='accord-head'>7. Bomdila: Dongru Siongju </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="7">
<Accordion.Header> <b className='accord-head'>8. Bameng: Kumar Waii</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="8">
<Accordion.Header> <b className='accord-head'>9. Chayangtajo: Hayeng Mangfi </b></Accordion.Header>
<Accordion.Body>

<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="9">
<Accordion.Header> <b className='accord-head'>10. Seppa-East: Ealing Tallang</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="10">
<Accordion.Header> <b className='accord-head'>11. Seppa-West: Mama Natung</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Home and Inter State Border Affairs <br />
Public Health Engineering & WaterSupply <br />
Department of Indigenous Affairs
</div>
</Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="11">
<Accordion.Header> <b className='accord-head'>12. Pakke-Kessang: Biyuram Wahge </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>






<br />
<Accordion.Item eventKey="12">
<Accordion.Header> <b className='accord-head'>13. Itanagar: Techi Kaso  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="13">
<Accordion.Header> <b className='accord-head'>14. Doimukh: Nabam Vivek </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="14">
<Accordion.Header> <b className='accord-head'>15. Sagalee: Er. Techi Ratu </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />

<Accordion.Item eventKey="15">
<Accordion.Header> <b className='accord-head'>16. Yachuli: Toko Tatung   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="16">
<Accordion.Header> <b className='accord-head'>17. Ziro: Er. Hage Aapa  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />


<Accordion.Item eventKey="17">
<Accordion.Header> <b className='accord-head'>18. Palin: Balo Raja   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Land Management <br />
Civil Aviation <br />
Urban Affairs (Urban Development, Town Planning,Urban Local Bodies, Housing)
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="18">
<Accordion.Header> <b className='accord-head'>19. Nyapin: Tai Nikio  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="19">
<Accordion.Header> <b className='accord-head'>20. Tali: Jikke Tako </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="20">
<Accordion.Header> <b className='accord-head'>21. Koloriang: Pani Taram  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="21">
<Accordion.Header> <b className='accord-head'>22. Nacho: Nakap Nalo   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'>23. Taliha: Nyato Dukam </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="23">
<Accordion.Header> <b className='accord-head'>24. Daporijo: Er. Taniya Soki  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="24">
<Accordion.Header> <b className='accord-head'>25. Raga: Er. Rotom Tebin  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="25">
<Accordion.Header> <b className='accord-head'>26. Dumporijo: Rode Bui </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="26">
<Accordion.Header> <b className='accord-head'>27. Liromoba: Pesi Jilen   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="27">
<Accordion.Header> <b className='accord-head'>28. Likabali: Kardo Nyigyor  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="28">
<Accordion.Header> <b className='accord-head'>29. Basar: Smt. Nyibi Jini Dirchi </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>



<br />


<Accordion.Item eventKey="29">
<Accordion.Header> <b className='accord-head'>30. Aalo-East: Kento Jini </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Law, Legislative & Justice <br />
Social Justice <br />
Empowerment and Tribal Affairs <br />
Sports & Youth Affairs <br />
</div>
</Accordion.Body>
</Accordion.Item>

<br />



<Accordion.Item eventKey="30">
<Accordion.Header> <b className='accord-head'>31. Aalo-West: Topin Ete </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />





<Accordion.Item eventKey="31">
<Accordion.Header> <b className='accord-head'>32. Rumgong: Er. Talem Taboh </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />


<Accordion.Item eventKey="32">
<Accordion.Header> <b className='accord-head'>33. Mechukha: P. D. Sona  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ul>
Education <br />
Rural Works <br />
Parliamentary Affairs <br />
Tourism <br />
Libraries <br />
</ul>
</div>
</Accordion.Body>
</Accordion.Item>



<br />





<Accordion.Item eventKey="33">
<Accordion.Header> <b className='accord-head'>34. Tuting: Alo Libang </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>





<br />



<Accordion.Item eventKey="34">
<Accordion.Header> <b className='accord-head'>35. Pangin: Ojing Tasing </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Rural Development and PanchayatiRaj <br />
Co-operation <br />
Transport (Secretariat Transport, Transport,Inland Water Transport, StateTransport Services)
</div>
</Accordion.Body>
</Accordion.Item>



<br />






<Accordion.Item eventKey="35">
<Accordion.Header> <b className='accord-head'>36. Nari-Koyu: Tojir Kadu  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="36">
<Accordion.Header> <b className='accord-head'>37. Pasighat-West: Ninong Ering  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="37">
<Accordion.Header> <b className='accord-head'>38. Pasighat-East: Er. Tapi Darang  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="38">
<Accordion.Header> <b className='accord-head'>39. Mebo: Oken Tayeng  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="38">
<Accordion.Header> <b className='accord-head'>40. Mariyang-Geku: Oni Panyang   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="40">
<Accordion.Header> <b className='accord-head'>41. Anini: Mopi Mihu  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="41">
<Accordion.Header> <b className='accord-head'>42. Dambuk: Punyo Apum  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="42">
<Accordion.Header> <b className='accord-head'>43. Roing: Mutchu Mithi   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="43">
<Accordion.Header> <b className='accord-head'>44. Tezu: Dr. Mohesh Chai  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />

<Accordion.Item eventKey="44">
<Accordion.Header> <b className='accord-head'>45. Hayuliang: Smt. Dasanglu Pul  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Women and Child Development <br />
Science & Technology <br />
Cultural Affairs (Art & Culture, Research, Gazeteers)
</div>
</Accordion.Body>
</Accordion.Item>

<br />


<Accordion.Item eventKey="45">
<Accordion.Header> <b className='accord-head'>46. Chowkham: Chowna Mein   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Hon'ble Deputy Chief Minister <br />
Finance, Planning & Investment <br />
Power & Non Conventional Energy Resources <br />
Tax & Excise <br />
State Lotteries <br />
Economics & Statistics
</div>
</Accordion.Body>
</Accordion.Item>

<br />








<Accordion.Item eventKey="46">
<Accordion.Header> <b className='accord-head'>47. Namsai: Zignu Namchoom  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="47">
<Accordion.Header> <b className='accord-head'>48. Lekang: Likha Soni   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="48">
<Accordion.Header> <b className='accord-head'>49. Bordumsa-Diyun: Nikh Kamin  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="49">
<Accordion.Header> <b className='accord-head'>50. Miao: Kamlung Mossang   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="50">
<Accordion.Header> <b className='accord-head'>51. Nampong: Laisam Simai </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="51">
<Accordion.Header> <b className='accord-head'>52. Changlang-South: Hamjong Tangha  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="52">
<Accordion.Header> <b className='accord-head'>53. Changlang-North: Tesam Pongte  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />


<Accordion.Item eventKey="53">
<Accordion.Header> <b className='accord-head'>54. Namsang: Wangki Lowang   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Environment & Forest <br />
Geology, Mining & Minerals <br />
Development of Tirap, Changlang &Longding
</div>
</Accordion.Body>
</Accordion.Item>

<br />


<Accordion.Item eventKey="54">
<Accordion.Header> <b className='accord-head'>55. Khonsa-East: Wanglam Sawin   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="55">
<Accordion.Header> <b className='accord-head'>56. Khonsa-West: Smt. Chakat Aboh </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="56">
<Accordion.Header> <b className='accord-head'>57. Borduria-Bogapani: Wangling Lowangdong  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>



<br />




<Accordion.Item eventKey="57">
<Accordion.Header> <b className='accord-head'>58. Kanubari: Gabriel D. Wangsu  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Agriculture <br />
Horticulture <br />
Animal Husbandry & Veterinary <br />
Dairy Development <br />
Fisheries <br />
Food & Civil Supplies <br />
Legal Metrology <br />
Consumer Affairs <br />
</div>
</Accordion.Body>
</Accordion.Item>


<br />



<Accordion.Item eventKey="58">
<Accordion.Header> <b className='accord-head'>59. Longding-Pumao: Thangang Wangham  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />




<Accordion.Item eventKey="59">
<Accordion.Header> <b className='accord-head'>60. Pongchao-Wakka: Honchun Ngandam  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>

<br />



</Accordion>

</div>

<hr className="separator" />

<WhatsAppShareButton />

<hr className="separator" />

<br/>
<div className="relatedposts">
<h3>Related Posts</h3>
<ol>
<li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
<li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
<li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
<li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
</ol>
</div>
<br />


{/* <h1 style={{
textAlign: 'center',
border: 'solid 2px black',
}}> Dummy Contents</h1> */}

<Footer />

<Outlet />
</>



);
}
export default Mpandmlaofarunachal;




