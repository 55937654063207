import React from 'react';
import './Business.css'
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer';
// import Businessbox from '../component/Businessbox'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import Businessslider from './Businessslider';


const Business = () => {
  return (
    <>
<Helmet>
  <title>Business Sector of Arunachal</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


  <hr className="separator"/>
    <Businessslider/>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Business / Services </b> </h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>What is Business and Start Up?</h3>
<ol>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>

</ol>

<br />
  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'>Food Related</h3>
<ol>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>

</ol>
{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>
<hr className="separator" />


                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Goods Business </h3>
  <ol>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>

</ol>
<br />

  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'>Services Business</h3>
<ol>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>

</ol>
<br />



{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>


<hr className="separator" />



                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Automobile (Parts) in Arunachal</h3>
  <ol>
    <li><Link className={"link-styles"} to="/Business">Tata Motors </Link> </li>
    <li><Link className={"link-styles"} to="/Business">Maruti Suzuki </Link> </li>
    <li><Link className={"link-styles"} to="/Business">Maruti Nexa</Link></li> 
    <br />
    <li><Link className={"link-styles"} to="/Business">Mahindra</Link></li>
    <li><Link className={"link-styles"} to="/Business">Toyota</Link></li>
    <li><Link className={"link-styles"} to="/Business">Isuzu </Link></li>
    <br />
    <li><Link className={"link-styles"} to="/Business">Kia </Link> </li>
    <li><Link className={"link-styles"} to="/Business">Honda </Link></li>
    <li><Link className={"link-styles"} to="/Business">MG Motor </Link></li>
    <br />
    <li><Link className={"link-styles"} to="/Business">Skoda </Link></li>
    <li><Link className={"link-styles"} to="/Business">Hyundai </Link></li>
    <li><Link className={"link-styles"} to="/Business">Renault </Link></li>
    <li><Link className={"link-styles"} to="/Business">Nissan </Link></li>
  </ol>

<br />
<h3 className='topic'>Two-wheelers (Parts) in Arunachal</h3>
<ol>
  <li><Link className={"link-styles"} to="/Business">TVS Motors </Link></li>
  <li><Link className={"link-styles"} to="/Business">Yamaha </Link></li>
  <li><Link className={"link-styles"} to="/Business">Honda </Link></li>
  <li><Link className={"link-styles"} to="/Business">Royal Enfield </Link></li>
</ol>
<br />



  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'>Shops</h3>
<br />
<ol>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Business">Coming Soon </Link> </li>
<li><Link className={"link-styles"} to="/Bizformat">Format </Link> </li>

</ol>

{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>



<br />
<hr className="separator" />
<br />

<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>How to Promote Business Culture in Arunachal Youths?</h3>

    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />

</Accordion>

  </div>
  <hr className="separator" />





    
  
    <WhatsAppShareButton/>
    <hr className="separator" />
    <br/>

    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>
    <Outlet />
    </>
  )
}

export default Business
