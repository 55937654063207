import React, { useState, useEffect } from 'react';

const images = [
  'images/da.jpg',
  'images/da.jpg',
  'images/da.jpg'
  
];

const Healthformat2slides = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="slider">
      <button onClick={prevImage} className="slider-button">❮</button>
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slider-image" />
      <button onClick={nextImage} className="slider-button">❯</button>
    </div>
  );
};

export default Healthformat2slides;


