import React, { useState } from 'react';
import './News.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Arunachaltrendingnews({ }) {
  return (

    <>


      <Helmet>
        <title>Arunachal Trending News</title>
        <meta name="description" content="Page description" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Arunachal Trending News </b></h3>

<hr className="separator" />


<div className="content-box">

  <div className="accordboxtop">
  Our Platform <b>Digital Arunachal</b> does not create or cover news. We just fetch the link of important news and issues prevailing in the state. Visit us to get important updates of Arunachal as a whole.

  </div>
 

  <br />

  

<Accordion>

<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. Latest News: (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'> 2. Important News:  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<Accordion.Item eventKey="23">
<Accordion.Header> <b className='accord-head'> 3. Important Issues in News:  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    
    <li><Link to="/Importantissuesinarunachal">Important Issues in Arunachal</Link></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />








<h3 className='topic'>Persons / Events in News:</h3>
<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Persons in News: (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Events in News </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      {/* <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}



      {/* <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}

{/* 
      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>5. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}

      <br />
      {/* <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>6. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>7. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>8. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>9. </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


</Accordion>


{/* 
<br />
<Accordion.Item eventKey="5">
<Accordion.Header><b className='accord-head'>6. </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  .
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="6">
<Accordion.Header> <b className='accord-head'>7. </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  .
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="7">
<Accordion.Header> <b className='accord-head'>8. </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  .
</div>
</Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="8">
<Accordion.Header> <b className='accord-head'>9. </b></Accordion.Header>
<Accordion.Body>

<div className="accord-box">
  .
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="9">
<Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  .
</div>
</Accordion.Body>
</Accordion.Item> */}


</Accordion>


  </div>


<hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Arunachaltrendingnews



