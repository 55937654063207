import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About"
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import './App.css';
import Education from "./education/Education";
import Business from "./business/Business"
import Health from "./health/Health";
import Tourism from "./tourism/Tourism";

import Formatclosed from "./formatall/Formatclosed";




// Home
import Latestposts from "./common/Latestposts";
import Arunachalgk from "./gk/Arunachalgk";
import Arunachaltransports from "./transport/Arunachaltransports";
import Sportsinarunachal from "./sports/Sportsinarunachal";

import Realestateinarunachal from "./realestate/Realestateinarunachal";

// Jobs Updates
import Jobupdates from "./jobs/Jobupdates";


import Govtofarunachalpradesh from "./politics/Govtofarunachalpradesh";

// Business
import Bizformat from "./business/Bizformat";

// Education


// Politics
import Mpandmlaofarunachal from "./politics/Mpandmlaofarunachal";
import Studentsandyouthpoliticsinarunachal from "./politics/Studentsandyouthpoliticsinarunachal";
import Politicsofarunachalpradesh from "./politics/Politicsofarunachalpradesh";





// GK
// 
// Personalities
import Politicalpersonalities from "./personalities/Politicalpersonalities";
import Businesstycoons from "./personalities/Businesstycoons";
import Socialworkers from "./personalities/Socialworkers";
import Sportspersonalities from "./personalities/Sportspersonalities";
import Fieldspecialist from "./personalities/Fieldspecialist";
import Emergingyouths from "./personalities/Emergingyouths";




// Health

import Formathealth from "./formatall/Formathealth";
import Healthformat2 from "./formatall/Healthformat2";
// Tourism



// News Upcoming Events
import Upcomingevents2024 from "./upcomingevents/Upcomingevents2024";
import Ansuelection2024 from "./upcomingevents/Ansuelection2024";
import Importantissuesinarunachal from "./news/Importantissuesinarunachal";


// Business
import Besthotelandrestroinarunachal from "./business/Besthotelandrestroinarunachal";


// Education
import Institutes from "./education/Institutes";





// Health
import Bkdentalcare from "./health/Bkdentalcare";
import Cmaayandhealthguide from "./health/Cmaayandhealthguide";


// Tourism


// All District
import Alldistrict from "./districts/Alldistrict";


// Arunachal trending news Arunachaltrendingnews
import Arunachaltrendingnews from "./news/Arunachaltrendingnews";


            //    Function starts from here

export default function App() {
return (
<BrowserRouter>
<Routes> 



                                          {/* Navbar */}
<Route path="/" element={<Layout />}>
<Route index element={<Home />} />
<Route path="latestposts" element={<Latestposts />} />
<Route path="formatclosed" element={<Formatclosed />} />


                 {/*News and Upcoming Events */}
 <Route path="upcomingevents2024" element={<Upcomingevents2024 />} />
 <Route path="ansuelection2024" element={<Ansuelection2024 />} />
 <Route path="importantissuesinarunachal" element={<Importantissuesinarunachal />} />

 


  
<Route path="education" element={<Education />} />


<Route path="business" element={<Business />} />
<Route path="bizformat" element={<Bizformat />} />

<Route path="mpandmlaofarunachal" element={<Mpandmlaofarunachal />} />




<Route path="health" element={<Health />} />
<Route path="tourism" element={<Tourism />} />
{/* <Route path="learnings" element={<Learnings />} /> */}

{/* Home */}



             {/* GK */}
            
<Route path="arunachalgk" element={<Arunachalgk />} />


 {/* <Route path="" element={< />} /> */}


              {/* Personalities */}
<Route path="politicalpersonalities" element={<Politicalpersonalities />} />
<Route path="businesstycoons" element={<Businesstycoons />} />
<Route path="socialworkers" element={<Socialworkers />} />
<Route path="sportspersonalities" element={<Sportspersonalities/>} />
<Route path="fieldspecialist" element={<Fieldspecialist />} />
<Route path="emergingyouths" element={<Emergingyouths />} />







<Route path="arunachaltransports" element={<Arunachaltransports />} />

<Route path="sportsinarunachal" element={<Sportsinarunachal />} />

<Route path="realestateinarunachal" element={<Realestateinarunachal />} />

<Route path="jobupdates" element={<Jobupdates />} />





{/* Politics */}
<Route path="govtofarunachalpradesh" element={<Govtofarunachalpradesh/>} />
<Route path="studentsandyouthpoliticsinarunachal" element={<Studentsandyouthpoliticsinarunachal/>} />

<Route path="politicsofarunachalpradesh" element={<Politicsofarunachalpradesh/>} />
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}

{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}

{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}

{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}





{/* Business */}
<Route path="besthotelandrestroinarunachal" element={<Besthotelandrestroinarunachal />} />




{/* Education */}
<Route path="institutes" element={<Institutes />} />




{/* Health */} 

<Route path="cmaayandhealthguide" element={<Cmaayandhealthguide />} />

<Route path="formathealth" element={<Formathealth />} />

<Route path="bkdentalacare" element={<Bkdentalcare />} />

<Route path="healthformat2" element={<Healthformat2 />} />


{/* Tourism */}

{/* All districts */} 
<Route path="alldistrict" element={<Alldistrict />} />


      {/* Arunachaltrendingnews */}
      <Route path="arunachaltrendingnews" element={<Arunachaltrendingnews/>} />

{/* Footer */}

<Route path="about" element={<About/>} />

<Route path="terms" element={<Terms />} />
<Route path="privacy" element={<Privacy />} />


{/* <Route path="*" element={<NoPage />} /> */}
</Route>
</Routes>
</BrowserRouter>
);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);