import React from 'react';
import './Tourism.css'
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import Tourismslider from './Tourismslider';

const Tourism = () => {
  return (
    <>
<Helmet>
  <title>Arunachal Tourism</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>
<hr className="separator" />

    <Tourismslider/>

<br/>
    <hr className="separator" />
<h2 className="latest-post" style={{color: "green"}}> <b> Arunachal Tourism </b></h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>For Arunachal Tourists</h3>
  <ol>
    <li><Link className={"link-styles"} to="/Tourism">Introduction to Arunachal Tourism</Link> </li>
    <li><Link className={"link-styles"} to="/Tourism">Tourist Places in Arunachal</Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/Tourism">Cultural Tourism in Arunachal</Link> </li>
    {/* <li><Link className={"link-styles"} to="/Tourism"></Link> </li>
    <li><Link className={"link-styles"} to="/Tourism"></Link> </li> */}

  <br />
<li><Link className={"link-styles"} to="/Arunachaltransports">Transports Services in Arunachal</Link> </li>
  </ol>
<br />


  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
<h3 className='topic'>Tourists Places</h3>
<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>
</div>




<div className="sbox1 part2">
  <h3 className='topic'>Tourists and Entertainment </h3>
  <ol>
    <li><Link className={"link-styles"} to="/Tourism"> </Link> </li>
    <li><Link className={"link-styles"} to="/Tourism">  </Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/Tourism">  </Link> </li>
    <li><Link className={"link-styles"} to="/Tourism"> </Link> </li>
    {/* <li><Link className={"link-styles"} to="/Tourism"></Link> </li> */}
  </ol>
 <br />

{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>



<hr className="separator" />
                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
<h3 className='topic'>Best Restaurants & Cafe in Arunachal </h3>
<ol>
    <li><Link className={"link-styles"} to="/Tourism">E & K's</Link> </li>
    <li><Link className={"link-styles"} to="/Tourism">AR01 Restro & bar</Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/Tourism">Golden Crown Restaurant</Link> </li>
    <li><Link className={"link-styles"} to="/Tourism">Moody Monk</Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/Tourism">Choe-Wangs's Kitchen</Link> </li>
    <li><Link className={"link-styles"} to="/Tourism">Cn Theros Restro & Bar</Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/Tourism">Gumsar Dining- Pan Asian Cuisine</Link> </li>
    {/* <li><Link className={"link-styles"} to="/Tourism"></Link> </li>
    <li><Link className={"link-styles"} to="/Tourism"></Link> </li> */}
    
  </ol>


  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'>Best Hotel & Resorts in Arunachal</h3>
<ol>
    <li><Link className={"link-styles"} to="/Besthotelandrestroinarunachal">Restaurants, Hotels and Resorts in Arunachal</Link> </li>
    <li><Link className={"link-styles"} to="/Tourism">Line Hotels in Arunachal</Link> </li>
    <br />

  </ol>

  {/* <ol>
  <li><Link className={"link-styles"} to="/Tourism"> </Link> </li>
  <li><Link className={"link-styles"} to="/Tourism"> </Link> </li>
  <li><Link className={"link-styles"} to="/Tourism"> </Link> </li>
  <li><Link className={"link-styles"} to="/Tourism"> </Link> </li>
  </ol> */}

</div>
</div>

<br />

<hr className="separator" />
<br />
                           {/* Second Part */}

<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>Arunachal Tourism: Know more !</h3>

<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>


<hr className="separator" />
    

  
    <WhatsAppShareButton/>
    <hr className="separator" />


    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />


    <Footer/>

    <Outlet />
    </>
  )
}

export default Tourism
