import { Outlet, Link } from "react-router-dom";


const Layout = () => {
  return (
    <>
      <nav className="navbar">
            <Link className={"link-style"} to="/">Digital Arunachal</Link>
           <b className="learnings"><a  href="https://www.gumbaja.com/">Learnings</a> </b>
            
      </nav>

      <nav className="navbar2">
           <Link className={"link-style"} to="/Education">Education</Link> 


            <Link className={"link-style"} to="/Business" style={{color: "yellow"}}>Business</Link>

            <Link className={"link-style"} to="/Tourism">Tourism</Link>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;