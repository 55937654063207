import React, { useState } from 'react';

const Whatsappchat = () => {
  const [name, setName] = useState('');
  const handleSend = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+917005369425&text=${encodeURIComponent(`Kindly Type Your Name: ${name}`)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
    <div className="autobox">
      <button className='whatsappchat' onClick={handleSend}>Send WhatsApp</button>
    </div>
    </>
  );
};

export default Whatsappchat;
