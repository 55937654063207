import React, { useState } from 'react';
import './Alldistrict.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer';
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Alldistrict({ }) {
  return (
<>


<Helmet>
  <title>All 27 Districts of Arunachal Pradesh</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>All 27 Districts of Arunachal </b></h3>

<hr className="separator" />




<div className="content-box">

  <div className="accordboxtop">
  At Present, there are 27 Districts in Arunachal Pradesh with the Bichom as the 27th District.

  </div>


<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Tawang.... (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. West Kameng</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. East Kameng</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. Bichom</b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>5. Keyi Panyor</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>6. Pakke Kessang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>7. Papum Pare</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>8. Kurung Kumey</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>9. Lower Subansiri</b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. Kamle</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. Kra Dadi</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. Upper Subansiri</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="12">
        <Accordion.Header> <b className='accord-head'>13. Lower Siang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="13">
        <Accordion.Header> <b className='accord-head'>14. Shi Yomi</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="14">
        <Accordion.Header> <b className='accord-head'>15. West Siang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="15">
        <Accordion.Header> <b className='accord-head'>16. Leparada</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="16">
        <Accordion.Header> <b className='accord-head'>17. Upper Siang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="17">
        <Accordion.Header> <b className='accord-head'>18. Siang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="18">
        <Accordion.Header> <b className='accord-head'>19. East Siang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="19">
        <Accordion.Header> <b className='accord-head'>20. Dibang Valley</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="20">
        <Accordion.Header> <b className='accord-head'>21. Lower Dibang Valley</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="21">
        <Accordion.Header> <b className='accord-head'>22. Anjaw</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="22">
        <Accordion.Header> <b className='accord-head'>23. Lohit</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="23">
        <Accordion.Header> <b className='accord-head'>24. Namsai</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="24">
        <Accordion.Header> <b className='accord-head'>25. Changlang</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="25">
        <Accordion.Header> <b className='accord-head'>26. Tirap</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="26">
        <Accordion.Header> <b className='accord-head'>27. Longding</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />

</Accordion>
</div>


<hr className="separator" />

<WhatsAppShareButton />

<hr className="separator" />

<br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Alldistrict



