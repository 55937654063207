import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'




const Latestposts = () => {
  return (
    <>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Latest Posts </b> </h2>

<hr className="separator" />
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>New Posts</h3>
  <ol>
  <li><Link className={"link-styles"} to="/Bkdentalacare">B K Dental Care, Naharlagun </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>


</ol>

</div>




<div className="sbox1 part2">
<h3 className='topic'>Recent Posts </h3>
<ol>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>

</ol>
<br />

</div>
</div>

<br />
<br />

<hr className="separator" />
                           {/* Second Part */}

    
  
    <WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>
    <Outlet />
    </>
  )
}

export default Latestposts


