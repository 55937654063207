import React, { useState } from 'react';
import './Common.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Upcomingevents2024({ }) {
  return (

    <>


      <Helmet>
        <title>Upcoming Events in Arunachal 2024</title>
        <meta name="description" content="Upcoming Events in Arunachal 2024" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Upcoming Events in Arunachal 2024 </b></h3>

<hr className="separator" />


<div className="content-box">

  <div className="accordboxtop">


  </div>
 

  

<Accordion>
<h3 className='topic'>Important Upcoming Events in Arunachal </h3>
<br />

<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. ANSU Election 2024-27: (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  
<li><Link className={"link-styles"} to="/Ansuelection2024">ANSU Election 2024 </Link></li>
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> 2. Ziro Music Festival 2024  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<Accordion.Item eventKey="23">
<Accordion.Header> <b className='accord-head'> 3. :  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />





<Accordion.Item eventKey="23">
<Accordion.Header> <b className='accord-head'> 4. :  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />






<Accordion.Item eventKey="35">
<Accordion.Header> <b className='accord-head'> 5. :  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />





</Accordion>


  </div>





<hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Upcomingevents2024;



