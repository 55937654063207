import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Outlet, Link } from "react-router-dom";

const Sportsslider = () => {

  return (
    <Carousel data-bs-theme="dark">


             {/* Slider 1 */}
      <Carousel.Item>
      
      <div className="carouselimage">
      <a href="https://www.digitalarunachal.com/">
        <img
          // className="d-block w-100"
          src="images/da.jpg"
          alt="First slide"
        />
        </a>

      </div>

        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      

           {/* Slider 2 */}
      <Carousel.Item>
      <div className="carouselimage">
      <Link to="/Business"> 
        <img
          src="images/digital.png"
          alt="Second slide"
        />
        </Link>
        </div>
       
        
        {/* <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

           {/* Slider 3 */}
      <Carousel.Item>
      <div className="carouselimage">
        <img
          src="images/da.jpg"
          alt="Third slide"
        />
        </div>
        {/* <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>

    </Carousel>
  );

};

<Outlet />

export default Sportsslider;
