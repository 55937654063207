import React from 'react';
import { Outlet, Link } from "react-router-dom";




const Footer = () => {
  return (
<>
<div className="footer">
    <Link className={"link-style"} to="/about">About / Contact</Link>
    <Link className={"link-style"} to="/terms">Terms</Link>
    <Link className={"link-style"} to="/privacy">Privacy</Link>


</div>


<Outlet />
</>
  )
}

export default Footer
