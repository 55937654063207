import React from 'react'
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Jobslider from './Jobslider';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import Whatsappchat from '../component/Whatsappchat';




const Jobupdates = () => {
  return (
    <>
<Helmet>
  <title>Arunachal Jobs Update</title>
  <meta name="description" content="Jobs upates in Arunachal Pradesh" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

<hr className="separator"/> 

   <Jobslider/>

   {/* <Formatslider/> */}
    <hr className="separator"/> 
<h2 className="latest-post" style={{color: "green"}}> <b>Job Updates</b> </h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Arunachal Job Updates</h3>

<Accordion>
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. APPSC Updates (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<li><a href="https://appsc.gov.in/upload/P85767_RECINS001/GDMO_advertisement.pdf">General Duty Medical Officer 2024: (Last Date: 29th August 2024)</a> </li>
<br />
<li><a href="https://appsc.gov.in/upload/P13065_RECINS001/Advertisement-_Lecturer_(DIET).pdf">Lecturer (DIET) 2024: (Last Date: 4th Sept. 2024)</a> </li>
<br />
<li><a href="https://appsc.gov.in/upload/P23162_RECINS001/Laboratory_Assistant_Civil_2024.pdf">Laboratory Assistant (Civil) 2024: (Last Date: 15th Sept. 2024)</a></li>


{/* <li><a href=""></a> </li>
<li><Link className={"link-styles"} to=""> </Link></li> */}
{/* <li><Link className={"link-styles"} to=""> </Link></li> */}



</ol>



</div>
</Accordion.Body>
</Accordion.Item>
<br />


<hr className="separator" />

<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2. APSSB Updates  </b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<ol>
  <li><a href="https://apssb.nic.in/Index/admit_card/ins/RECINS001">Admit Cards for the Combined Graduate Level Examination 2024 (Available: Click Here)</a></li>
  <br />
  <li><a href="https://apssb.nic.in/upload/RECINS001/Combined_Secondary_Level_Examination_2024001.pdf">Combined Secondary Level Examination, 2024 (Last Date: 9th September 2024)</a></li>
  <br />
  <li><a href="https://apssb.nic.in/upload/RECINS001/Advt_(Technical_2024).pdf"> Non-Ministerial (Technical) Examination-2024 (Last Date: 3rd October 2024) </a></li>
  <br />

  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}

 
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li>
  <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}

</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<hr className="separator" />

<Accordion.Item eventKey="55">
<Accordion.Header> <b className='accord-head'>= Contact us to Apply: (Click here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <Whatsappchat/>
</div>
</Accordion.Body>
</Accordion.Item>

</Accordion>


<br />
<br />

    <b style={{color: "green", fontSize: "20px"}}><li>Daily Jobs Update: <a href="https://whatsapp.com/channel/0029ValQ8aOFsn0WF7owXw1J">Click here </a></li> </b>
<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>All India Job Updates </h3>
<ol>
  <li><a href="https://www.gumbaja.com/Ibpspoform2024">IBPS PO Form 2024 (4455 Posts)</a></li>
  <br />
  <li><a href="https://www.gumbaja.com/Irdaiassistantmanagerform2024">IRDAI Assistant Manager Form 2024 (49 Posts)</a> </li>

  <li><a href="https://www.gumbaja.com/Nainitalbankpoandotherposts">Nainital Bank PO and Others (25 Posts) </a> </li>

  <br />
  {/* <li><Link className={"link-styles"} to="">  </Link></li>
  <li><Link className={"link-styles"} to="">  </Link></li>
  <br /> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li>
  <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
</ol>

<hr className="separator" />

<Accordion>
<Accordion.Item eventKey="55">
<Accordion.Header> <b className='accord-head'>= Contact us to Apply: (Click here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <Whatsappchat/>
</div>
</Accordion.Body>
</Accordion.Item>

</Accordion>


</div>
</div>




<hr className="separator" />


      {/* Accord Institutes: Copied from Education Section */}

                                <div className="content-box">
<h3 className='topic' style={{color: "red", fontSize: "26px",}}>Coaching Institutes in Arunachal:</h3>

<Accordion>
<Accordion.Item eventKey="3">
<Accordion.Header><b className='accord-head'>1. UPSC CSE / APPSC: Top Coaching Institutes</b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><a href="https://gumbaja.com/">Subject-wise Study Materials</a></li>
<li><Link to="/education">ALS </Link></li> 
<li><Link className={"links"} to="/education">Success Point </Link></li> 

<li><Link className={"links"} to="/education">IBE Coaching Center, Itanagar </Link></li> 
<li><Link className={"links"} to="/education">Jom's Coaching Institute (TGT-PGT), Itanagar </Link></li> 
<li><Link className={"links"} to="/education">Photon IAS, ICR </Link></li> 
<li><Link className={"links"} to="/education">CSM Academy, Itanagar </Link></li>
<li><Link className={"links"} to="/education">Edunachal, Itanagar & Naharlagun </Link></li>
<br />
<li><Link className={"link-styles"} to="/education">L.M. IAS Institute </Link></li> 
<li><Link className={"link-styles"} to="/education">Naukri Maker </Link></li>
<li><Link className={"link-styles"} to="/education">Roy Academy, ICR </Link></li>

<li><Link className={"link-styles"} to="/education">A.T. Institute Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">The AIMER'S Institute (AE/JE) </Link></li>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="4">
<Accordion.Header><b className='accord-head'>2. APSSB: Top Coaching Institutes</b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>    
<li><Link className={"link-styles"} to="/education">Nesol, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Catalyst Classes, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">The Bridge Academy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">GOPRO educare, Itanagar and Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">BH Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Edumiles Academy </Link></li>
{/* <li><Link className={"link-styles"} to="/education"> </Link></li> */}
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>  */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="5">
<Accordion.Header><b className='accord-head'>3. Medical Entrance </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/education">Spectrum Coaching Center, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">The Pie Academy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Nimbus Tutorials, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Gurukul Institute, Itanagar </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">Lalan's Integrated Academy </Link></li> 
<li><Link className={"link-styles"} to="/education">Scholar Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Roy Academy, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">Shankalp Vidyapeeth, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Edunachal Academy, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Mahendra's Institutes, Itanagar </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">IBE Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">INSIGHT GURU, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">IMPACT INSTITUTE, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Catalyst Classes, Itanagar and Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">Noble Institute, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Paul's Learning, Itanagar </Link></li> 
{/* <li><Link className={"link-styles"} to="/education"> </Link></li>
<li><Link className={"link-styles"} to="/education"> </Link></li>
<li><Link className={"link-styles"} to="/education"> </Link></li>
<li><Link className={"link-styles"} to="/education"> </Link></li> */}
</ol>
</div>
</Accordion.Body>
</Accordion.Item>



<br />


<Accordion.Item eventKey="6">
<Accordion.Header><b className='accord-head'>3. Libraries cum Study Room </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
<ol>
<li><Link className={"link-styles"} to="/education">Aspirants Library, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">Study Nest, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">24x7 Self-Study Library, Itanagar </Link></li> 
<li><Link className={"link-styles"} to="/education">Study Nest, Naharlagun </Link></li> 
<br />
<li><Link className={"link-styles"} to="/education">24x7 SELF-STUDY LIBRARY, Naharlagun </Link></li> 
<li><Link className={"link-styles"} to="/education">STP Study Room cum Library, Naharlagun </Link></li> 
</ol>
</div>
</Accordion.Body>
</Accordion.Item>

<br />



</Accordion>

  </div>

  <br />
<hr className="separator" />
<br />
                           {/* Scams */}

<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>Paper Leakage Scams in Arunachal:</h3>

    <Accordion>
      <Accordion.Item eventKey="3">
        <Accordion.Header> <b className='accord-head'>1. APPSC Scam</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header> <b className='accord-head'>2. APSSB Scam</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header> <b className='accord-head'>3. Illegal Appointments Issues</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />

</Accordion>

  </div>

<hr className="separator" />
    
  
    <WhatsAppShareButton/>
    <hr className="separator" />

    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>

    <Outlet />
    </>
  )
}

export default Jobupdates

