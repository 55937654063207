import React from 'react';
import './Gk.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer';

import WhatsAppShareButton from '../component/WhatsAppShareButton';
import { Helmet } from 'react-helmet';
import Gkslider from './Gkslider';
import Accordion from 'react-bootstrap/Accordion';


const Arunachalgk = () => {
  return (
    <>


<Helmet>
  <title>Arunachal GK</title>
  <meta name="description" content="Arunachal GK" />
  <meta property="og:title" content="Arunachal GK" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


                          {/* Sliders */}
<hr className="separator"/>
<Gkslider/>
<br/>

                              {/* Heading */}
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Arunachal GK</b> </h2>
<hr className="separator"/>

                                     {/* Accordian Work Start */}
<div className="content-box">
  <br />
<Accordion>
                       
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. Arunachal Pradesh: A Brief Description .. (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ul>
<li><b>Geography: </b> Arunachal Pradesh (Meaning “Land of the Rising Sun) is situated in the extreme northeast of the country between 26°28’ East (latitudes) and 91°30’ to 97°30’ North (longitudes). <br />
- Total Area: 83,743 square km. <br />
<b>It shares international boundaries with three Countries: </b> <br />
1. Bhutan (West) <br />
2. China (North) <br />
3. Myanmar (Southeast). <br />
<br />
<b>It shares interstate boundaries with two States: </b> <br />
1. Nagaland (South and Southeast) <br />
2. Assam (South and Southwest).
<br /><br />
<b> The capital is Itanagar.</b> <br />
- It is a state comprising of 27 districts.</li>

<br /><br />
<li><b>History:</b> Before 1972 it was known as North Eastern Frontier Agency (NEFA) under the Assam constituency.
The name Arunachal Pradesh was given by <b>Sri Bibhabasu Das Shastri</b>, the then Director of Research and K.A.A. Raja , the then Chief Commissioner of Arunachal Pradesh on 20 January 1972. <br />
On 20 February 1987, Arunachal Pradesh became a state.</li>

<br /><br />

<li><b>Demography:</b> Population (2011): 1,382,611. <br />
- Density= 17/km2. <br />
- The literacy rate of the state is 65.38%.br
According to the 2011 census, Arunachal Pradesh has a total population of 1383727 of which 713912 are males and 669815 are females. <br />
- The scheduled tribe population accounts for 64.22% of the total population, as against 88.50% in 1961.</li> 
<br /><br />

<li><b>People: </b>It is a state comprising of <b>26 major tribes</b> including various sub tribes. <br /> It is equally rich and diverse in both cultural and ecological aspects, however for a long time much of the world remained elusive to this richness because of the state’s remote location.
A place brimming with tremendous anthropological richness, Arunachal Pradesh is home to several groups of indigenous people. <br />
<b>Broadly speaking there are three cultural groups:</b> <br />
1. <b>First group (Tani Clans) </b> comprising of Apatanis, Adis, Galos, Mishmis, Nyishis, Tagins, Akas, etc worship the Sun and Moon God (Donyi-Polo and Christian).
<br />
2. <b>Second Group being Monpas and Sherdukpen</b> of Tawang and West Kameng districts who are followers of the tradition of Mahayana Buddhism. <br />

3. While <b>the Third Group including Noctes, Wanchos and Khampti</b> (tribal communities of TLCN – Tirap, Longding, Changlang, Namsai) follow basic Vaishnavism and Buddhism respectively and are ruled by a hereditary chief.
An exploration of the cultural roots of Arunachal Pradesh therefore, takes us on a fascinating anthropological journey
</li>
<br />

<li><b>Culture and Tradition: </b>
Having lived in close proximity with nature since centuries, people were depended on Mother Nature for everything. From traditional handicrafts, fishing and hunting tools etc – the people developed many unique skills over the years and even today many of these skills are passed down. <br />
<b>Apong (rice/millet wine)</b> is the prominent drink induced by the people, especially in festivals. <br /> <br />
Dance is an important element of the people’s heritage and traditional dancing along with priest/priestess chants, war dance and ritual dance with Buddhist connection etc are mostly performed by men. <br />
Bhuyan, Popir, Ponung/Ponu, etc is performed in groups by men and women for the celebration of joyous and fortunate events in the future. <br />
A rich oral collection of folklores and chants, usually in a ballad tone, have been passed down from centuries about the historical events, myths, power of spirits and calling of deities.</li>

<br /> <br />

<li><b>Important Points: </b> <br />
<b>Chief minister:</b> Pema Khandu <br />
<b>Governor:</b> Kaiwalya Trivikram Parnaik <br />
<b>State flower:</b> Rhynchostylis Retusa (Retusa) <br />
<b>State tree:</b> Dipterocarpus retusus (Hollong) <br />
<b>State bird:</b> Great hornbill <br />
<b>State animal:</b> (Mithun) Gayal <br />
<b>Literacy Rate (2011): </b> 65.38% <br /> <br />
<b>Assembly Constituency:</b> 60 Seats<br />
<b>Parliamentary Constituency: </b> 2 Seats: (West: Kiren Rijiju) and (East: Tapir Gao) <br />
<b>Rajya Sabha: 1 Seat: </b>(Nabam Rebia)</li>

</ul>


</div>
</Accordion.Body>
</Accordion.Item>
<br />

                                    {/* History */}
<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2. History of Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">


<ul>
  <li><b>Arunachal Pradesh name at Ancient Time: </b> In the ancient Greeco-Roman language the state of Arunachal Pradesh was part of the land known as Seres.
  The people living in Seres were called Serica. So, literally people of Arunachal Pradesh were called as Serica during the ancient time.</li> <br />

  <li><b>Arunachal Pradesh name at Medieval Time:</b> The people of Arunachal Pradesh were called with different names at different parts of Assam as Arunachal Pradesh was under Assam province during this period. <br />
  In the upper Assam, people of Arunachal Pradesh were called as Saumar.
   And in the northern Assam, people of Arunachal Pradesh were called Uttarakul.</li> <br />


  <li> <b>Arunachal Pradesh name in the Modern Time:</b> <br />
  Arunachal Pradesh was known as NEF (Northeastern Frontier) from 1825 to 1913. <br />
  Arunachal Pradesh was known as NEFT (Northeastern Frontier Tract) from 1914 to 1951. <br />
  Arunachal Pradesh was known as NEFA (Northeastern Frontier Agency) from 1952 to 1972. <br />
  Arunachal Pradesh was known as NEFA on 20th January, 1972.
  </li> <br />

  <li><b>Pre NEFA – NEFT:</b> NEFA was formerly known as NEFT (Northeastern Frontier Tract), which was a province of Assam under British India. <br />
It became a part of Assam state after independence of India. <br />
NEFT was formed in 1914 from the tribal districts of Darrang and Lakhimpur of Assam province. <br />
NEFT was divided into: <br />
(i) Central & Eastern sections: <br />
- Formed from Dibrugarh Frontier Tract which was formed under Assam province in 1884. <br />
- Later renamed to Sadiya Frontier Tract. <br />
(ii) Western Sections: <br />
- Later renamed to Balipara Frontier Tract. </li> <br />


  <li><b>Govt of India Act 1935:</b> Under this Act, three administrative areas: <br />
(i). Sadiya Frontier Tract. <br />
(ii). Balipara Frontier Tract <br />
(iii). Lakhimpur Frontier Tract ( fromed in 1919 from Assam province) were declared the excluded areas of province of Assam. </li> <br />


  <li><b>Northeastern Frontier Tracts Regulation- 1943:</b> Under the regulation, Tirap Frontier Tract was formed from certain areas of Sadiya Tract & Lakhimpur Frontier Tract.</li> <br />

  <li><b>Assam Frontier (administration of justice) Regulation- 1943:</b> This regulation was passed by governor of Assam by excercising his power given in sections 2 of section 92 of Govt of Invdia Act 1935.
  This act was a landmark step in the history of Arunachal Pradesh.
  </li> <br />


  <li><b>Bifurcation of Sadiya Frontier Tract- 1948:</b> In 1948, sadiya frontier tract was bifurcated into:
(i). Abor Hills District.
(ii). Mishmi Hills District in 1950, plain portions of NEFT : Abor Hills, Balipara Frontier Tract, Miahmi Hills, Tirap Frontier Tract were trsnsferred to Assam Govt and the rest became one of the tribal areas in Assam state.</li> <br />

  <li><b>Formation of NEFA: 1951 & post NEFA:</b> 
In 1951, NEFA was formed from the conglomeration of:
(I). Balipara Frontier Tract.
(II). Tirap Frontier Tract.
(III). Abor Hills Districts.
(iv) Mishmi Hills District.
(v). Naga Tribal Areas
</li> <br />


  <li><b>Frontier Divisions Of NEFA- 1954:</b> On 26th January 1954, NEFA had 6 frontier divisions for administrative convenience:
Kameng: formerly sela sub agency
Subansiri: formerly subansiri areas
Tirap: formerly tirap frontier agency
Siang: formerly abor hills district
Lohit: formerly mishmi hills district
Tuensang</li> <br />

  <li><b>Separation of Tuensang from NEFA- 1957:</b> On Dec 01- 1957, Tuensang got separated from NEFA to attach with Naga hills district to form Naga hills- Ttuensang areas now.
  i.e Tuensang areas = Naga hills = Tuensang + Naga hills district.</li> <br />





  <li><b>Administrative Power Transfer:</b> On August 01-1965, Administrative Power of NEFA was transferred to Ministry of Home Affairs from Ministry of External Affairs- Govt of India.</li>
  <br />
  <li><b>Districts Formation under NEFA:</b> On December 01- 1965, five frontier divisions of NEFA became its Five Districts:
(i). Kameng
(ii). Subansiri
(iii). Siang
(iv). Lohit
(v). Tirap. <br />
Deputy Commissioner became the head of the districts in place of political officer.</li> <br />

<li><b>Agency Council- 1967 & Councillors:</b> Agency of council was constituted in 1967 for better administration under NEFA.
It was a administrative body of 26 members (23 elected and 3 nominated).
The first sitting of the council was held at shilling on 3rd Dec 1969 with chairman Shri B.K. Nehru the then governor of Assam.
It was later renamed to Pradesh council on January 1972:
- K.A.A Raja the then chief commissioner became its chairman.
- He nominated 5 councillors each from 5 districts among the council members to take care of various departments.
- The first 5 councillors were: (i). Shri P.K. Thungon – Chief Councillor (ii). Tomo Riba (iii). Wangpa Lowing (iv). Soben Tayeng (v). Tadar Tang.
These 5 councillors were nominated to cabinet ministers on 15th august 1975:
- Chief councillor P.K Thungon became the First Chief minister of union territory of Arunachal Pradesh.</li> <br />

<li><b>From UT to Statehood:</b> Arunachal Pradesh got its union territory on 20th January 1972.
Arunachal Pradesh got its name from NEFA (NEFA to Arunachal Pradesh) on 21st January 1972 (by Bhibabasu Das Shastri).
Shri B.N Singh and Gegong Apang were sworn in as the First Governor and Chief Minister of the state of A.P.
Administrative Headquarter of Arunachal Pradesh was transferred to Itanagar from Shillong in 1974.</li> <br />

<li><b>The Inner Line Regulation 1873: What is Bengal Eastern Frontier Regulation in 1873?</b> 
 <br />
 For inward travel into Arunachal Pradesh, Meghalaya, Manipur, Mizoram, under the Foreigners (Protected Areas) Order 1958, requires an official document issued by the Government which is known as the <b>Inner Line Permit</b>. This permit system goes back to 1873 when the British Government instituted the Bengal Eastern Frontier Regulations. So, let us first look into what this Regulation is all about. <br />
  The Britishers first entered North East India by conquering Assam in <b>the Anglo Burmese War of 1824-26.</b> They occupied the conquered states but pursued “isolationist policy” for the North East Frontier Tracts (present Arunachal Pradesh) intending to leave the tribesman alone. <br /> The first administrative policy of the British in the North East was the introduction of Bengal Eastern Frontier Regulation in 1873 also known as the <b>Inner Line Regulation</b>. <br />
  <b> Under Section 2 </b>of the Regulation an “inner line” was prescribed by the state authority for limiting movement. <br />
  <b> Section 4,</b> gave the authority power to provide a permit for passing or residing in any of these “inner line” areas. The idea behind this regulation was to protect the culture and identity of indigenous tribes in the region, giving them some autonomy to look into their personal tribal affairs. <br />
  During British rule, the regulation prohibited the “British Subjects” or Indians from entering into the protected or restricted areas but post-independence the word “British Subject” was replaced by “Citizens of India”. <br />
  At present, the Bengal Eastern Frontier Regulation continues to apply, in present-day Arunachal Pradesh, Nagaland and Mizoram.</li>

</ul>
</div>
</Accordion.Body>
</Accordion.Item>


                {/* Important Events */}
<br />
<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. Important Events of Arunachal in Chronological order</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
   <h3 className='topic'>Election System in Arunachal Pradesh</h3>
<ul>
<li><b>MP (Parliamentary) Elections:</b> Since AP got its Union Territory on 20th January 1972, there were 3 MPs (2 seats for Lok Sabha & 1 nominated seat for Rajya Sabha). <br />
- Prior to status of Union Territory, there was only nominated MP for Lok Sabha. <br />
- Shri <b>C. K. Gohain</b> was the first MP from NEFA, nominated at Lok Sabha in 1952. <br />
- Late <b>Tadak Basar</b>  was the first nominated MP for Rajya Sabha in 1972. <br />
- The first MP election for 2 seats in Lok Sabha was held on 19th March 1977. <br />
- The first MP election for 1 seat in Rajya Sabha was held in 1978. </li> <br />

<h3 className='topic'>LEGISLATIVE ASSEMBLY IN ARUNACHAL PRADESH</h3>
<li>The Legislative Assembly institution in Arunachal Pradesh was introduced on the recommendations by <b> Bhanot Committee </b>on 15th August 1975 on approval of the Govt of India.</li>
<li>Bhanot committee was constituted under the membership of G. K. Gohain, IAS, Joint Secretary – Ministry of Home Affairs & C. K. Moorthy, Director general of SC & ST welfare board, govt of India GOI on 11th April, 1964. <br />
Key Recommendations & Changes were: <br />
Post of chief commissioner was redesigned as Lieutenant Governor. <br />
Shri K.A.A. Raja was sworn in as first Lieutenant Governor of AP on 15th August, 1975. <br />
The Pradesh council was converted into provisional legislative assembly. <br />
Post of chief councillors, councillors and members were redesigned as chief minister, ministers and MLA respectively. <br /> 
</li> <br />
<li><b>The first chief minister and ministers and MLAs of Pradesh Council are as follows: </b> <br />
Chief Minister: Prem Khandu Thungon <br />
Ministers: Tomo Riba, Tadar Tang, Sobeng Tayeng and Wangpa Lowang. 
</li> <br /> <br />

<li><b>Election to Legislative Assembly of union territory of Arunachal Pradesh:</b> The election to legislative assembly of union territory of Arunachal Pradesh was held for 33 MLA seats (30 elected and 3 nominated). <br />
First union territory legislative assembly: 25th February , 1978. <br />
Second union territory legislative assembly: 3rd January, 1980 <br />

</li> <br />
<li> <b> Election to Legislative Assembly the state of Arunachal Pradesh:</b> The election to legislative assembly of union territory of Arunachal Pradesh was held for 60 MLA seats. <br />


First state legislative assembly: 27th February, 1990. <br />
Second state legislative assembly: 11th March, 1995. <br />
Third state legislative assembly: 3rd October, 1999. <br />
Fourth state legislative assembly: 7th October, 2004. <br />
Fifth state legislative assembly: 13th October, 2009. <br />
Sixth state legislative assembly: 9th April, 2014. <br />
Seventh legislative assembly: 11th April, 2019. <br />
Eighth legislative assembly: 19th April, 2024.</li>
</ul>


</div>
</Accordion.Body>
</Accordion.Item>


{/* Governors CMs */}
<br />
<Accordion.Item eventKey="3">
<Accordion.Header> <b className='accord-head'>4. Governors, CMs and Speakers of Arunachal</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
 <br />   <h3 className='topic'>Governors</h3>
<ol>
  <li>Bhisna Narain Singh
  </li>Tenure: 20/02/1987 to 18/03/1987
  <li>R. D. Pradhan</li> Tenure: 19/03/1987 to 16/03/1990
  <li>Dr. Gopal Singh</li> Tenure: 17/03/1990 to 08/05/1990
  <li>Devi Das Thakur</li> Tenure: 09/05/1990 to 16/03/1991
  <li>Loknath Misra</li> Tenure: 17/03/1991 to 25/03/1991
  <li>S. N. Dwivedy</li> Tenure: 26/03/1991 to 04/07/1993
  <li>Madhukar Dighe</li> Tenure: 05/07/1993 to 20/10/1993
  <li>Mata Prasad</li> Tenure: 21/10/1993 to 16/05/1999
  <li>Lt. General (retired) Srinivas Kumar Sinha</li> Tenure: 17/05/1999 to 01/08/1999
  <li>Arvind Dave</li> Tenure: 02/08/1999 to 12/06/2003
  <li>V. C . Pande</li> Tenure: 13/06/2003 to 15/12/2004
  <li>S. K. Singh</li> Tenure: 16/12/2004 to 23/01/2007
  <li>M. M. Jacob (acting governor)</li> Tenure: 24/01/2007 to 06/04/2007
  <li>K. Sankaranarayanan (acting governor)</li> Tenure: 07/04/2007 to 14/04/2007
  <li>S. K. Singh</li> Tenure: 15/04/2007 to 03/09/2007
  <li>K. Sankaranarayanan (acting governor)</li> Tenure: 04/09/2007 to 26/01/2008
  <li>General (retired) Joginder Jaswant Singh</li> Tenure: 27/01/2008 to 28/05/2013
  <li>Lt. General (retired) Nirbhay Sharma</li> Tenure: 29/05/2013 to 31/05/2015
  <li>J. P. Rajkhowa</li> Tenure: 01/06/2015 to 09/07/2016
  <li>Tathagata Roy</li> Tenure: 10/07/2016 to 12/08/2016
  <li>J. P. Rajkhowa</li> Tenure: 13/08/2016 to 13/09/2016
  <li>V. Shanmuganathan</li> Tenure: 14/09/2016 to 27/01//2017
  <li>Padmanabha Balakrishna Acharya (acting governor)</li> Tenure: 28/01/2017 to 02/10/2017
  <li>Brigadier (Dr) B. D. Mishra (retired)</li> Tenure: 03/10/2017
  <li>Kaiwalya Trivikram Parnaik</li> Tenure: 16/02/2023 – Till Date
</ol>


<div className="cm">
 <br />   <h3 className='topic'>Chief Ministers</h3>

 <ol>
  <li> Prem Khandu Thungon</li>
  Tenure: 13/08/1975 to 18/09/1979 <br />
  Party: Janta party
  <br />

  <li>Tomo Riba</li>
   Tenure: 18/09/1979 to 03/11/1979 <br />
   Party: People’s Party of Arunachal <br />


  <li> Gegong Apang</li>
  Tenure: 18/01/1980 to 19/01/1999 <br />
  Party: Indian National Congress <br />


  <li>Mukut Mithi</li>
  Tenure: 19/01/1999 to 03/08/2003 <br />
  Party: Arunachal Congress, People’s Party of Arunachal
<br />
  <li>Gegong Apang</li>
  Tenure: 03/08/2003 to 09/04/2007 <br />
  Party: United Democratic Front, Bharatiya Janta Party, Indian national Congress   <br />

  <li>Dorjee Khandu</li>
  Tenure: 09/04/2007 to 30/04/2011 <br />
  Party: People’s Party of Arunachal <br />

  <li>Jarbom Gamlin </li>
  Tenure: 05/05/2011 to 01/11/2011 <br />
  Party: Indian National Congress <br />


  <li>Nabam Tuki</li>
  Tenure: 01/11/2011 to 26/01/2016 <br />
  Party: Indian National Congress <br />

  <li>Kalikho Pul</li>
  Tenure: 19/02/2016 to 13/07/2016 <br />
  Party: People’s Party of Arunachal <br />

  <li>Nabam Tuki</li>
  Tenure: 13/07/2016 to 17/07/2016 <br />
  Party: Indian National Congress

<li>Pema Khandu</li>
Tenure: 17/07/2016 till date <br />
Party: Indian National Congress, People’s Party of Arunachal, Bharatiya Janta Party
 </ol>



</div>

<br />
<br />

<div className="speakers">


 <br /> <h3 className='topic'>Speakers</h3>
 <ol>
  <li>Nokming Namati</li>
  <li>Padi Yubbe</li>
  <li>Nokming Namati</li>
  <li>T. L. Rajkumar</li>
  <li>T. L. Rajkumar (Second term)</li>
  <li>Lijum Ronya</li>
  <li>Tako Dabi</li>
  <li>Chowna Mein</li>
  <li>Tamiyo Taga</li>
  <li>Setong Sena</li>
  <li>Setong Sena (Second term)</li>
  <li>Wangling Lowangdang</li>
  <li>Nabam Rebia</li>
  <li>Wangki Lowang</li>
  <li>Nabam Rebia</li>
  <li>Tenzing Norbu Thongdok</li>
  <li>Pasang Dorjee Sona</li>
 </ol>




<br /><br />
<b>NOTE:</b> There was President’s rule in the state from: <br />
- 3rd November, 1979 to 18th January, 1980. <br />
- 26th January, 2016 to 19th February, 2016.
</div>
  </div>
</Accordion.Body>
</Accordion.Item>


                {/* All Districts */}
<br />
<Accordion.Item eventKey="4">
<Accordion.Header> <b className='accord-head'>5. All Districts of Arunachal</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic'>Name of Districts and Headquarters</h3>

<ul style={{listStyle: "none"}}>
  <li><b>1. Tirap</b> (Khonsa)</li>
  <li><b>2. Lohit</b> (Tezu)</li>
  <li><b>3. Dibang Valley</b> (Anini)</li>
  <li><b>4. East Siang</b> (Pasighat)</li>
  <li><b>5. East Kameng</b> (Seppa)</li>
  <li><b>6. Lower Subansiri</b> (Ziro)</li>
  <li><b>7. Upper Subansiri </b> (Daporijo)</li>
  <li><b>8. West Kameng</b> (Bomdila)</li>
  <li><b>9. West Siang</b> (Aalo)</li>
  <li><b>10. Tawang</b> (Tawang)</li>
  <li><b>11. Changlang</b> (Changlang)</li>
  <li><b>12. Papum Pare</b> (Yupia)</li>
  <li><b>13. Upper Siang</b> (Yingkiong)</li>
  <li><b>14. Kurung Kumey</b> (Koloriang)</li>
  <li><b>15. Lower Dibang Valley</b> (Roing)</li>
  <li><b>16. Longding</b> (Longding)</li>
  <li><b>17. Anjaw</b> (Hawai)</li>
  <li><b>18. Namsai</b> (Namsai)</li>
  <li><b>19. Kra Daadi</b> (Jamin)</li>
  <li><b>20. Siang</b> (Boleng)</li>
  <li><b>21. Lower Siang</b> (Likabali)</li>
  <li><b>22. Kamle</b> (Raga)</li>
  <li><b>23. Lepa Rada</b> (Basar) </li>
  <li><b>24. Shi Yomi</b> (Tato)</li>
  <li><b>25. Pakke-Kessang</b> (Lemmi)</li>
  <li><b>26. Bichom</b> ()</li>
  <li><b>27. Keyi Panyor</b> () </li>
</ul>
 
  </div>
</Accordion.Body>
</Accordion.Item>

                     {/* All Tribes */}
<br />
<Accordion.Item eventKey="5">
<Accordion.Header> <b className='accord-head'>6. All Tribes of Arunachal Pradesh (History)</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
    <ol>
      <li>Nyishi</li>
      <li>Adi</li>
      <li>Galo</li>
      <li>Tagin</li>
      <li>Aka</li>
      <li>Apatani</li>
      <li>Idu Mishmi</li>
      <li>Memba</li>
      <li>Monpa</li>
      <li>Digaru Mishmi</li>
      <li>Sherdukpen</li>
      <li>Nocte</li>
      <li>Tai Khamti</li>
      <li>Singpho</li>
      <li>Khamba</li>
      <li>Mijis</li>
      <li>Wanchos</li>
      <li>Tangsa</li>
      <li>More</li>
    </ol>
  </div>
</Accordion.Body>
</Accordion.Item>


                  {/* Arunachal Dances and Culture */}
<br />
<Accordion.Item eventKey="6">
<Accordion.Header> <b className='accord-head'>7. Arunachal: Dance and Culture</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">

<h3 className='topic'>Tribes and Their Dances: Arunachal Pradeshl </h3>
<ol>
  <li>Adi</li>
  

  <b>1. Delong: </b> Pasi kongki: It is a folk dance performed only by men. Each dancer usually holds a dao (machete) while performing this dance. In this dance, the Abang (song) narrates how the pasis used used to March on foot from their home in the mountains to the markets in the plains to procure iron.
The abang narrates the hardships they faced on the journey because of the dangerous terrains.
According to the abang, after procuring iron from the plains, they used to bring them to Pasimide (blacksmith of the Pasis), who would then craft machetes from the iron.
<br />
<b>2. Pasi kongki:</b>  <br />
<b>3. Ponung: </b> In ponung folk dance, a Miri (the man leading the dance) holds a Yoksa (sword) and stands inside a circle formed by women around him.
The women hold each other’s hands. The Miri shakes the yoksaand recites a line and the women utter the chorus after him; this goes on during the folk dance.
During the performance, the women rock forth and back and at the same time move to their right.
Ponung is performed on important occasions. <br />
<b>4. Tapu:</b> <br />
<b>5. Yakjong:</b>




<br /><br />
  <li>Aka:</li>
  
<b>1. Sadinuksto:</b> Sadinuksto folk dance is generally performed in marriages, for guest entertainments, or on the occasion of the construction of a new house. <br />
In this folk dance, a boy comes forward and dances for a while and then recedes.
After that, a girl comes forward and dances for a while and then recedes. This goes on till all the boys and girls have danced.
The dance is not performed to any songs.

<br /><br />

  <li>Apatani:</li>
1. Daminda: <br />

2. Harang-piyo: Harang-piyo is a folk dance of Apatanis performed during Dree festival by acting as agriculture pests and worms. <br />

3. Hiirii-Khanting: Hiirii-Khanting is a folk dance of Apatanis. It is a group dance in which both men and women participate.
Lively songs and music are used in the performance of this dance. Hiirii-Khaniing is performed for merriment. This dance conveys the message of the triumph of goodness over evil. <br />

4. Hurkani: <br />

5. Pakhu-Itu: <br />

6. Takutam: <br />


<li>Digaru Mishmi:</li>
1. Buiya <br />
2. Nuiya
<br /><br />


<li>Galo:</li>
  1. Popir
  <br /><br />


  <li> Idu Mishmi:</li>
1. Igu: Idu-Mishmis are strong believers of spirits and forces of nature.
Igu dance is a ceremonial dance that is performed to keep malevolent spirits at bay and prevent sickness.
- Igu means priest in Idu Mishmi language. Igus are highly regarded in their community.
It is believed that igus can ivoke both benevolent and malevolent spirits and cure various illness.
- The dance is mainly performed by igu. His assistants perform to create an environment of festivity.
The dance begins with lengthy song. The assistants join in chorus to beats of musical instruments.
<br /><br />


<li>Memba:</li>
  1. Brah



<br /><br />
<li>Monpa:</li> 
<b>1. Aji Lhamu:</b> It is a prominent folk dance of Monpas performed during Losar festival.
There are five characters in this dance: Gyeli, Lhamu, Lhum, Nyapa (the protagonist) and Nyaro (the antagonist).
Lhamu andLhum are female characters.
Lhamu was a fairy from heaven who later became the queen of Gyali.
The dance depicts the marriage ceremony of Lhamu with King Choegyal Norzang.
<br />

<b>2. Lion and Peacock dance:</b> It is a folk dance performed by Monpas during festivals. It depicts the story of the friendship of a saint with a Peacock and two Lions.
The characters of this dance are; the saint and two snow Lions (performed by dancers concealed under the costume of the animals).
Sometimes, a Snow Lion cub is also included in the dance along with the two adult snow Lions.
The performers of the snow Lions imitate the gait of the animal and dance with the saint to the beats of drums and cymbals.
The legend behind lion and peacock dance:
According to the legend, a saint named Tenteling went to the top of the Gangrikarpo mountain, somewhere in the Himalayas for meditation. He meditated there without food and water for three years.
The snow lions lived near the place where he was meditating. The snow lions were astonished to see him meditating for such a long time without food and water.
The snow lions visited the saint and offered their milk to him. A peacock also came across the saint and gave him fruits.
The peacock and the snow lions attended to the saint till he completed his meditation and became friends with him.
After the completion of meditation, the saint and the snow lions danced together. Lion and peacock dance signifies the fact that prosperity comes when there is harmony and cooperation among all living things on earth.
<br />

<b>3. Yak dance:</b> It is one of the most famous dances of the Monpas. The masked dancers represent the members of a family who are said to have discovered yak with many hundred years ago. The discovery of yak resolved the family’s internal property disputes and provided a permanent source of wealth and property to the entire community. It is believed that performing the dance relieved them all of their worries and troubles.

<br />
<br />

  <li>Nocte:</li>
  1. Chalo

<br /> <br />

<li>Nyishi:</li>
1. Rikham Pada
2. Buya
3. Roppi
<br />
1. Rikham Pada: Rikham Pada folk dance is performed during festivals and important functions.
According to a legend, Rikham Pada and Rinyam Yami were brothers and both of the were very good looking.
They wore beautiful dresses which added to their attractiveness. Many young girls desired them as their husband.
Rikham pada is depicted in Rikham pada folk dance.
<br /><br />

<li>Sherdukpen:</li> 
1. Bardo Chham: The theme of the dance revolves around the victory of good over evil. Sherdukpens believe that there are twelve evil animals, each of which appears each month of the year.
The performers wear colourful animal masks and enact the fight between good and evil to the beats of drums and cymbals in background. <br /><br />
2. Yak dance

<br /><br />


  <li>Tai Khamti:</li> 
1. Ka Fifai: Ka Fifai is a drama performed through dance. The actors of the drama are; a girl, the girl’s father, a ghost, a king, ministers and the army of the king.
The girl and her father stroll in the garden when the ghost arrives and kidnaps the girl. The girl’s father approaches the king and asks for his help to free her daughter from the captivity of the ghost. The king orders his ministers to be ready for a war and thus the army of the king goes in search of the ghost and captures him. The king warns the ghost not to kidnap girls again in future. The ghost agrees to the king’s warning and bows his head down.
<br />
2. Kha Kingnarakingnari (Peacock dance)

<br /><br />


  <li>Tagin:</li>  
1. Si-Ome <br />
2. Achi Riabu <br />
3. Anyi Chungney

<br /><br />

  <li>Tangsa:</li>
  1. Moh

  <br />
  <br />

  <li>Wancho:</li> 
  <b>1. Ozele</b>

  <br /><br />
  <li>Mijis:</li> 
  1. Dumai



  <li>Memba:</li> 
  1. Broh

  <br /><br />


  <li>Khamba:</li>
  1. Troh

  <br />
  <br />

  <li>Singpho:</li>
  1. Manglup
</ol>
  </div>
</Accordion.Body>
</Accordion.Item>


                           {/* Major Festivals of Arunachal */}
<br />
<Accordion.Item eventKey="7">
<Accordion.Header> <b className='accord-head'>8. Major Festivals of Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic'>Major Festivals of Arunachal </h3>

<ol>
<li>Si-Donyi (6 January):</li> It is celebrated by people of Tagin Tribe in the month of January.
The name Si-Donyi is derived from two words: Si (earth) and Donyi (the sun).
This festival is celebrated after harvest and is celebrated in order to propitiate God Si and Goddess Donyi.
During this festival ‘etting’ rice powder mixed with apong (rice- beer) is made into paste and everyone is applied with it.
Men take care of the outdoor preparatory tasks and women are engaged in making dresses, ornaments, rice beer and other preparations.
Priests pray to God Si and Goddess Donyi to prevent malevolent spirits from harming people and ask for good harvest and health.
<br />
<br />


<li>Reh (1 February):</li> Reh, the most important festival of Idu Mishmi Tribe is celebrated to seek prosperity and welfare of the community and a good harvest.
People of Idu tribes believe that they are the children of Goddess “Nanyi Intaya” and they can only get her blessings when they perform rituals and celebrate Reh.
The festival is an expensive affair, therefore people start with all the arrangements and preparations months in advance. A local system called Ada involves the collection of animals, money and other essential items from the community for the celebrations.
Yunyiphri, the preparation of rice beer is undertaken three to four months before the actual celebration.
The festival starts with the sacrifice of Mithuns for offering to Goddess Nanyi Intaya. It is celebrated for six consecutive days,
the first day is called Andropu which is observed by offering prayers for the festival to pass on without obstacles. People make merry and dance during the night.
The second day is Eyanli and involves animal sacrifices and guests are entertained with rice, meat and rice beer.
The third day is Iyili on which a grand feast is arranged.
On Ilyiromunyi, the fourth day, priests perform rituals for wealth, prosperity and wellbeing.
The fifth day is called Aru–Go on which remaining food and drinks are shared among different villages.
The last day of the festival is Etoanu. On this day, blood-smeared seeds are sown in the fields and rice beer is poured for Goddess Nanyi Intaya.
<br /><br />

<li>Boori- Boot Yullo (6 February):</li> Boori-Boot Yullo is a festival of Nyishi tribe mainly in Kamle, Upper Subansiri and lower Subansiri districts.
This festival is the way to offer gratitude for successful harvest of the crops.
The people pray to the spirits of Boori-Boot so that it blesses them with prosperity and protect them from diseases.
It is a 3 days festival, celebrated in the month of February (4-6 February).
Boori-Boot means to get together irrespective of caste, creed, age and sex to celebrate the arrival of spring. <br /> <br />

<li>Shapawng Yawng Manau Poi (14 February):</li> It is annual festival of Singpho Tribe of Arunachal Pradesh.
The word Poi means festival and the word Manau means dance thus, Shapawng Yawng Manau Poi means dance festival in the honour of Shapawng Yawng.
Singpho youths in their traditional dress perform folk dances. Prayers are offered to the almighty for peace and prosperity.
The legend behind the festival: singphos believe themselves to be the descendents of Shapawng Yawng. Shapawng Yawng’s son, Tingli Yawng wanted to get the blessings of his father and Mathum Mata, the creator of the world. So, he organised the first manau poi which later went on to be known as “Shapawng Yawng Manau Poi” in the memory of shapawng yawng.

<br /> <br />



<li>Oriah (16 February): </li> Oriah is the most significant festival of Wanchos. They believe that by celebrating Oriah, deities who control peace, harmony and prosperity are propitiated.
The festival is celebrated with songs, folk dances and rituals.
Folks wear traditional attires and ornaments.
The festival begins by sacrificing animals to propitiate deities.
Villagers exchange bamboo segments filled with rice beer as a greeting or a mark of goodwill. Folk dances are performed around jangban, which is a pole installed for the festival.

<br /><br />
<li>Nyokum Yullo, (26th February):</li> Nyokum is the festival of the Nyishi Tribe. Nyishis believe that human beings can live a life of peace and prosperity only when a perfect harmony is maintained between man, spirits and nature. They believe that wars, accidental deaths, famine, flood, drought, earthquakes and epidemics occur due to the anger of spirits of nature.
Nyokum yullo is celebrated to propitiate benevolent spirits to protect people from malevolent spirits and for the harmony and prosperity of the people.
During the festival, Nyishis pray to Goddess Nyokum for more production of food grains in future harvests, for warding off famines, for preventing droughts and floods, for preventing animals or insects from destroying crops, for preventing accidents and deaths from accidents and for preventing wars and epidemics.
The term Nyokum came from two words “nyok” meaning land and “kum” meaning people.
It is a two days festival in which people enjoys singing, dancing and gathering up. Men and women hold hands and form a circle to perform a traditional dance.
The major rituals are performed by the high priest and prayers are offered to the spirits to bring tranquillity and prosperity to each household.
<br /><br />


<li>Mopin (5 April): </li> Mopin is a popular festival celebrated by Galo Tribe of Arunachal Pradesh.
It is celebrated to worship Goddess Mopin. It is believed that Goddess Mopin taught people about agriculture.
In this festival, the Goddess of welfare, peace, wealth, prosperity and wisdom is propitiated in grand celebration.
This is normally celebrated in order to get rid of natural calamities diseases, effects of evil spirits and for good harvest, health, wealth and prosperity.
The rituals associated with Mopin festival are believed to drive away malevolent spirits and bring blessings, peace and prosperity.
People wear traditional white attires in the festival.
Popir folk dance is performed by women. It is the most popular dance during the festival.
Rice flour is applied on one another’s faces ; it is considered as symbol of love, unity and harmony.
<br />
<br />

<li>Pongtu Kuh (11th April): </li> Pongtu kuh is an agricultural festival celebrated by Tutsa.
The festival is celebrated after the harvesting of millet to welcome the new year.
Prayer is offered to God Rangkathok for bumper harvest and protection of crops from natural calamities and pests.
Chicken is sacrificed and offered to Rangkathok. The sacrifice is followed by a forecast of the year which is done by rubbing a bamboo stick.
Colourful folk dance is the major attraction of the festival.
<br /><br />


<li>Sangken (14 April): </li> It is celebrated by the Theravada Buddhists. It is celebrated to usher in the new year as per the Buddhist religious calendar.
The duration of the festival is determined by the religious authorities.
It is marked with sacred image of Buddha is installed in the shrine (holy place – monastery).
The timings for the ‘decent’ and the ‘ascent’ of the images of the Buddha along with other rituals are published in a widely distributed letter called the ‘sangken cha’.
Here people pour clean, scented water on the idols. People, irrespective of age, pour water on one another, signifying the cleansing of the body and soul and wish for purity of self and others.
Prayers for the well being of all are chanted by the monks during the festival.


<br /><br />
<li>Moh-Mol (25 April): </li> Moh-Mol is a prominent pre-harvest festival celebrated by the Tangsa Community of changlang district Arunachal Pradesh.
Moh-Mol marks the completion of the sowing season and the end of toil and labour in the fields.
It signifies the beginning of a new year, a time for merriment and is celebrated with great zeal and enthusiasm.
Prayers are offered to bring in a prosperous harvest season and for wealth, longevity of the community and freedom from diseases.
People wear colourful costumes and ornaments during Moh-Mol. Arts and crafts are on display during the festival. A traditional musical instrument named Bong is played during the celebrations. Sapolo folk dance is performed during the festival.
Some communities of Tangsa bid farewell to the departed souls of their family and pray for them.

<br /><br />
<li>Dree (5 July):</li> It is celebrated by the Apatani tribe of Lower Subansiri District.
It is celebrated to appease Gods to receive their blessings for prosperity, protection of plants from pests and insects, warding off famines and epidemics and a good harvest.
A local beer called Apong is prepared by each house during the festival.
During the festival people offer sacrifice of fowls, eggs and animals to the Gods and pray to the deities of Tamu, Metii, Danyi and Harniang.
The venue for the worships & prayers is decided by the high local priest (Nyibu), which is often a placed near the paddy fields.
God Tami is offered a prayer so that he protects the plants from harmful pests and insects.
God Metii is offered prayer for controlling of famine and epidemics.
God Danyi is offered prayer for the protection and prosperity of mankind.
God Harniang is offered prayer for ensuring the fertility of soil and preventing the paddy plants from getting dried.

<br /><br />
<li>Solung (1st September):</li> It is an agricultural festival celebrated by people of Adi Tribe. It is celebrated in the first week of September – after the planting of paddy to seek prosperity and a good harvest and protection from malevolent spirits.
The date for the festival is different each year and is decided by kebang or the village council keeping the convenience of the villagers in mind and once the date is fixed the preparation of the local beer apong starts.
The celebration of festival consists of three main parts: Sopi – yekpi, Binyat and Ekop.
Sopi-yekpi is the inaugural day during which mithuns and pigs are sacrificed and offered to Dadi Bote, the God of domestic animals.
The second part of the festival is Binyat during which the Goddess of food and crops i.e Kine Nane is worshipped.
Ekop is the third part of the festival. During ekop, a miri (singer) narrates the story of Nibo (who is considered as forefather of Adis). Rituals for protecting people for malevolent spirits are performed during ekop.
Ponung folk dance is performed by women during the festival. The folk dance is accompanied by Abang(a song) which is sung by a Miri.

<br /><br />
<li>Loku (25th November): </li> Also called as Chalo Loku.
Loku is the main festival of the Nocte Tribe of Tirap district and is celebrated to bid farewell to the winter.
The term loku came from two words from the local dialect – Lofe which means to drive out and Rangku which means the season.
It is an agricultural festival celebrated in the month of November.
The date of the festival is decided by the elders according to the days of the waxing moon. It is a three days festival.
First day is called Phamlamja– on this day animals like pigs and buffaloes are slaughtered for meat and the village people engage in preparations for the next day. Also people check their traditional costumes, which would be worn during the celebrations.
The second day of the festival is known as Chamkatja- on this day the Noctes enable the male members of the family to become full-fledged members of the Pang (decision making committee) It is in fact mandatory that each household performs a ritual called Chamkat for each of its male member who has attained adolescence on the day of chamaktja. The families who have observed chamkat invite the dancers to perform at their houses and in return offer food and drinks to the participants
The third and the final day is called Thanlangja- on this day villagers, irrespective of sex, age or social status participate in folk dances. The dances are performed at the house of the chief and in the premises of the pang. It is also a day for the people to visit their relatives and friend.

<br /><br />
<li>Losar (Date fixed by the concerned DC):</li> It is the festival of Monpa tribe of Tawang and West Kameng district of Arunachal Pradesh and it is celebrated to welcome a new year.
The festival falls usually in the month of February and early March and lasts for about 8-15 days.
During this festival homes are cleaned, prayers are offered, religious flags are hoisted at top of each house, holy scriptures are read and lamps with butter are lit in all the houses.
The local deity is also worshipped by the Monpas for the welfare of the society and the people.
Losar is also the time to relish the taste of local made drinks and savour the traditional cuisine.

<br /><br />
<li>Pakke Paga Festival (18th to 21st January): </li> The Pakke Paga Festival also called “Great Indian Hornbill” Festival.
It is celebrated at Seijosa in Pakke kessang district in Arunachal Pradesh in month of January from 18 to 21st.
The festival ground is at bank of Pakke River in Seijosa.
Seijosa is home to the Pakke Tiger Reserve & this has been a successful model of conservation in the entire country.

</ol>
</div>
</Accordion.Body>
</Accordion.Item>


                     {/* Tribal Village Councils */}
<br />
<Accordion.Item eventKey="8">
<Accordion.Header> <b className='accord-head'>9. Tribal Village Councils of Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
                       <h3 className='topic'>Tribal village Councils Of Arunachal Pradesh</h3>

 <b>The principle roles of a council are:</b> To provide for government of its area at the local level. <br />
- Specifically, councils are required to act as representatives, informed and responsible decision maker in the interests of its community. <br />
- For smooth administration of the village. <br />
- For the settlement of disputes. <br />
- To deliver justice at village.

<br />
<br />

<b>1. Kebang (Adi Village Council):</b> There are four types: <br />
1. Erang or Opin Kebang: family or clan. <br />
2. DolungKebang: village level. <br />
3. Bane or Bango Kebang: area level. <br />
4. Bogumbokangkebang: highest council for all aspects of society.

<br /><br />

<b>2. Melly or Melle (Aka Village Council): </b> <br />
- Members are experienced & knowledgeable elders. <br />
- Naggu or chief is the head of the council.

<br /> <br />

<b>3. Bulyang (Apatani Village Council): </b> Kinds and Types: <br />
1. Neha bulyang <br />
2. Kidi bulyang <br />
3. Kiimer bulyang <br />
4. Mudo bulyang <br />
Each family clan of the society have their own Bulyang. <br />
The meeting are held on the public platform of the clan or the village called <b>Lapang</b>.
<br />
<br />

<b>4. Dulu Keba (Galo Village Council):</b> <br />
1. The head is called <b>Keba Abo.</b> <br />
2. The council settles the disputes amicably.

<br />
<br />

<b>5. Mokchup (Khamti Village Council):</b> <br />
1. The chief occupies have the highest position in the society. <br />
2. They administer justice based on the principle of Hinayana sect of the Buddhism.

<br /><br />

<b>6. Mishmi Village Council </b>: <br />
There are three groups of Mishmis and they have their own village councils. <br />
1. Idu or Chulikata Mishmi: <b>Abbala or Kabaya.</b>  <br />
2. Taraon or Digaru Mishmi: <b>Pharai.</b>  <br />
3. Kaman or Miju Mishmi: <b>Pharai.</b> 


<br />
<br />


<b>7. Mongjombana or Mang Majom (Monpa Village Council): </b> <br />
- Consulted under the village chief called <b>Tsrogen.</b>  <br />
- The members have a wide knowledge and experiences in customary laws justices.


<br />
<br />
<b>8. Ngothum (Nocte Village Council):</b> <br />
- Headed by village chief called <b>Lowing</b>. <br />
- The meeting is organized at the house of the village chief.


<br />
<br />
<b>9. Nyille (Nyishi Village Council): </b>



<br />
<br />
<b>10. Jung or Blue (Sherdukpen Village Council):</b> <br />
- Headed by village chief called <b>Thukaba or Thiko.</b> <br />
- The council is constituted in democratic manner.


<br />
<br />

<b>11. Keba (Tagin Village Council):</b> The council is constituted in democratic manner by influential villagers.


<br />
<br />
<b>12. Wanchu, Wangcha or Ngajowa (Wancho Village Council): </b> It is the council constituted with elders of the village.
The council is headed by the village chief called <b>Wangham. </b> 
 The meeting is organized at chief’s house.


<br />
<br />
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="9">
<Accordion.Header> <b className='accord-head'>10. Religions in Arunachal</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
    <ol>
      <li>Donyi Polo</li>
      <li>Christian</li>
      <li>Hindu</li>
      <li>Buddhist</li>
      <li>Muslim</li>
      <li>Tribal Indigenous Religions</li>
    </ol>
  </div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="10">
<Accordion.Header> <b className='accord-head'>11. Mountain Peaks in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
    .
  </div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="11">
<Accordion.Header> <b className='accord-head'>12. Mountain Passes in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>




                           {/* lakes */}
<br />
<Accordion.Item eventKey="12">
<Accordion.Header> <b className='accord-head'>13. Lakes in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">

<h3 className='topic'>Important Lakes of Arunachal Pradesh are listed below:</h3>
   <br />

<b>1. Ganga Lake: </b>
It is located in Itanagar under Papum Pare district.
It is also known as <b>Gekar Sinyik</b> by local name.
<br /><br />

<b>2. Sangestar Tso: </b>
It is located in Tawang District.
It is also known as <b>Madhuri Lake</b>.
It is located on the way between Tawang Township and Bhumla Pass in Tawang District.

<br /><br />
<b>3. Pangaleng Tso Lake (PT Tso Lake) or Pankang Teng Tso Lake: </b>
It is located in Tawang District.
Many locals and tourist visit this for a picnic and other holiday events as it is located at 18 km from township of Tawang.
The name has been derived from Pankang tree whose log has been collected by the Tibetan government until 1914.

<br /><br />
<b>4. Mehao Lake: </b>
It is located in Roing town of Lower Dibang Valley District.
It is within the Mehao wildlife sanctuary.

<br /><br />
<b>5. Nagula Lake: </b>
It is located at Tawang District.
This lake remains frozen in most of the season.
The best time to visit this lake is around April – May or September – October.

<br /><br />
<b>6. Shonga-Tser Lake: </b>
It is a lake located in Tawang District.
It is believed that the lake was formed aftermath of the earthquake that strucked Arunachal Pradesh in 1950 as the beautiful scenery is grown in the middle of the lake.
The lake is at a distance of 40 km from Tawang townships.

<br /><br />

<b>7. Glow Lake: </b>
It is located in Wakro Town of Lohit District.

<br /><br />

<b>8. Lake of No Return: </b>

It is a lake considered to be India’s Bermuda triangle.
It is located in Changlang district along the Indo-Burma border in the Pangsau Pass.

<br /><br />

<b>9. Banga Jang Lake: </b>
It is located in Tawang District.
It is one of the most popular spot of tourist and is located within the township of Tawang.

<br /><br />
<b>10. Sally Lake: </b>
It is a lake located at 3 km away from Roing Town of Lower Dibang Valley district.

<br /><br />
<b>11. Kampona Pond: </b>
It is situated at Idili village at a distance of 17 km from Roing Town of Lower Dibang Valley District.
Kampona in local Adi dialect means good looking.
  </div>
</Accordion.Body>
</Accordion.Item>


                  {/* Biosphere Reserves */}
<br />
<Accordion.Item eventKey="13">
<Accordion.Header> <b className='accord-head'>14. Biosphere Reserves in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic'>Basics of Biosphere Reserves</h3>
- Biosphere reserves are areas of terrestrial and coastal ecosystems promoting solutions to reconcile the conservation of biodiversity with its sustainable use. <br />
- Biosphere reserve programme was initiated by UNESCO in 1971 under its “Man and the Biosphere” programme. <br /> <br />
<b>Biosphere reserve is divided into different inter-related zones:</b> <br />
<b>(1). Core Zone:</b> It is the innermost minimally disturbed zone and represents important genetic reservoirs having exceptional scientific interest. <br />
<b>(2). Buffer zone:</b> It surrounds core zone and limited human activities are allowed in this area such as resource use strategies, research and education. <br />
<b>(3). Transition zone:</b> It is the outermost part of a Biosphere Reserve. This is a zone of cooperation where conservation knowledge and management skills are applied and uses of BRs are managed in harmony with the different objectives of the Biosphere Reserve. <br /> <br />
The first biosphere reserve of the world was established in 1979, since then the network of biosphere reserves has increased to 631 in 119 countries across the world. <br /> <br />
Presently, there are 18 notified biosphere reserves in India , out of which, one is in Arunachal Pradesh: <b> Dihang-Dibang or Dehang-Debang.</b>

<br /><br />


<b>Dihang-Dibang or Dehang-Debang Biosphere Reserve:</b> Arunachal Pradesh
This biosphere reserve was constituted in 1998 and is the only biosphere reserve in Arunachal Pradesh. <br />

<b>Geography:</b>
The Mouling National Park and the Dibang Wildlife Sanctuary are located fully or partly within this biosphere reserve. <br />
The reserve spreads over Three Districts: Dibang Valley, Upper Siang and West Siang District. <br />
It covers high mountains of Eastern Hiamalaya and Mishmi Hills.
The type of vegetation found in this biosphere reserve can be grouped as: sub-tropical broad leafed forest, sub-tropical pine forest, temperate broad leafed forest, temperate conifer, sub-alpine woody shrub, alpine meadow (mountain tundra), bamboo brakes and grassland. <br />
- The habitat in Dihang-Dibang ranges from tropical wet evergreen in the river gorges to sub-tropical, temperate, alpine and permanent snow.
<br /> <br />



<b>Fauna:</b> The reserve is rich in wildlife.
Rare mammals such as Mishmi Takin, Red Goral, Musk Deer (at least two sub-species), Red Panda, Asiatic Black Bear, Occasional Tiger and Gongshan Muntjac Occur. <br />
<b>Birds:</b> among birds there are the rare Sclater’s Onal and Blyth’s Tragopan. <br />
Two flying squirrels have been discovered from the vicinity of this reserve: these are named as Mechuka Giant Flying Squirrel (Petauristamechukaensis) and Mishmi Hills Giant Flying Squirrel (Petauristamishmiensis).
</div>
</Accordion.Body>
</Accordion.Item>


              {/* National Park */}
<br />
<Accordion.Item eventKey="14">
<Accordion.Header> <b className='accord-head'>15. National Parks in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic'>National Parks in Arunachal Pradesh</h3>

A national park is an area officially recognized by a nation’s government.
National parks are often made to protect the animals that live inside them or the land itself. <br />
There are many National parks in the world. The first one established (1872) was the United States Yellowstone National Park located in the state of Wyoming (USA).
<br />
<br />
<b>There are two National Parks in Arunachal Pradesh: Mouling National Park and Namdhapa National Park. </b>b

<br />
<br />
<b>1. Mouling National Park: </b>
Located in Upper Siang district (some parts of West Siang and East Siang districts) of the state.
Total area of the park is about 483 sq.km.
The name Mouling has been derived from highest peak named Mouling as per local faith and belief.
Mouling is an Adi word which means red poison or red blood, which is believed to be red latex from a tree species found locally.
The area is thought to have a large number of poisonous snakes, but this cannot be verified as the ecology of the area has been sparsely explored. <br />
This park has been recognized as one of the 18 biodiversity hotspots of the world.
Mouling is known for its three varieties of Goats – Antelopes (locally known as Takin), Coral and Serow.
<br />
<br />
<b>Major Flora:</b> <br />
Terminalia myriocarpa, Terminalia bellirica, Terminalia citrina, Altingiaexcelsa, Canarium strictum, Castanopsis indica, Albizia sapium, Amoorawallichii, Chukrasiatabularis, tooan ciliate, Duabanga grandiflora, Cinnamomum glanduliferum, Micheliaexcelsa, Micheliamontana, Micheliakisopa, Dilleniapentagyna, Betula alnoides, Arundinaria maling, Dendrocalamushamiltoni, Bambusa pallida, Calamus erectus etc. <br /> <br />

<b>Major Fauna:</b> <br />
<b>Mammals:</b> Takin, Coral, Serow, Wild Buffalo, Tiger, Panther, Red Panda, Elephant, Hog Deer, Barking Deer, Sambar, Hoolock Gibbon etc. <br />
<b>Birds:</b> white-winged Wood Duck, Tragopan, Monal Pheasants, Giant Hornbill, Forest Eagle, Owl, Snowy Throated Babblers, Green Cochoa, Chakma, Peafowl, Fairy Bluebird, assam Bamboo Patridge etc. <br />
<b>Reptiles:</b> Indian Python, Reticulated Python, King Cobra, Monitor Lizard etc.
<br />



<hr className="separator" />
<br />

<b>2. Namdhapa National Park: </b> Namdapha was established as a wildlife sanctuary in 1972.
It was declared a Tiger Reserve and National Park in 1983. <br />

<b>Geography: </b> The park is located in Changlang District. <br />
Total area of the park is about 1807.82 sq.km. <br />
It is the 3rd largest National Park in India in terms of area.
The park has extensive bamboo forests to complement the secondary forests.
Namdapha lies along the turbulent <b>Noa-Dihing river</b>  at the international border between India and Myanmar. <br />
The National Park is nestled between the Patkai range and Dapha Bum range of Mishmi Hills.

<br /> <br />
<b>Major Flora: </b>Many floral species are found in the park. Many plants found in Namdapha are rare, endangered and endemic species. <br />
Some species, such as Pinus merkusii (Samutaran pine) and Abies delavayi (Delavay’s Fir) are found nowhere else in India except at this park. <br />
The most famous local medicinal plant Mishmi Teeta (Coptiteeta), which is used by the local tribal for all kinds of disease is available here but its export has been banned.
<br /> <br />


<b>Major Fauna:</b>
<b>Mammals: </b> Tiger, Clouded Leopard, Common Leopard, Snow Leopard, Hoolock Gibbon, Elephants, Black Bear, Indian Bison, Gaur, Sambar, Serow, Dholes, Musk Deer, Mouse Deer, Wolf, Leopard Cat, Bharal, Himalaya Tahr, Wild Buffalo, Wolves, Asiatic Black Bears, Red Panda, Red Fox, Yellow Throated Marten, Slow Loris, Capped Langurs, Assamese Macaques, Rhesus Macaques etc. This park is famous for being the only park in India to have four big cat species i.e Leopard, Tiger, clouded Leopard and Snow Leopard. <br />
<b>Birds:</b> White-winged Wood Duck, Tragopan, Monal Pheasants, Giant Hornbill, Forest Eagle, Owl, Snowy Throated Babblers, Rufous-necked Hornbill, Green Cochoa, Chakma, Peafowl, Fairy Bluebird, Assam Patridge etc. <br />
<b>Reptiles:</b> Indian Python, Reticulated Python, King Cobra, Monitor Lizard etc.


  </div>
</Accordion.Body>
</Accordion.Item>


                     {/* Wildlife Sanctuary */}
<br />
<Accordion.Item eventKey="15">
<Accordion.Header> <b className='accord-head'>16. Wildlife Sanctuaries in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic'>What is Wildlife Sanctuary?</h3>

<b>Wildlife Sanctuary</b> can be understood as the regions where wildlife and their habitat is protected from any disturbance, while national park is the are of countryside, which is specifically designated for wildlife, where they can live freely and use the natural resources. <br />
When it comes to restrictions, National Parks are highly restricted areas, which are not open to all the people, whereas Wildlife Sanctuaries have lesser restrictions than the National Parks.

<br /><br />

<b>Below are the Important Wildlife Sanctuaries of Arunachal Pradesh:</b>

<br /><br />
<b>1. Daying Ering Memorial (Lali) Wildlife Sanctuary:</b> Located in east Siang District.
Earlier it was called as Lali Wildlife Sanctuary and was renamed as Daying Ering Memorial wildlife Sanctuary in 1986. <br /> <br />

<b>2. Dibang Wildlife Sanctuary:</b> Located in Dibang valley District.
This is the biggest wildlife sanctuary in Arunachal Pradesh.

<br /> <br />
<b>3. Eaglenest Wildlife Sanctuary: </b> Located in West Kameng District.

<br /> <br />
<b>4. Sessa Orchid Wildlife Sanctuary: </b> Located in West Kameng District.
Most of its territory is claimed by Bugun Tribe.
It is a home to more than 200 species of Orchid.
An Orchid Research Centre (Orchidarium) is set up at Tipi near Sessa in West Kameng District– this Orchidarium was inaugurated by K. A. A. Raja on 16th of August, 1972.

<br /> <br />
<b>5. Itanagar Wildlife Sanctuary:</b> Located in Papum Pare District. 


<br /> <br />
<b>6. Kamlang Wildlife Sanctuary:</b> Located in Lohit District.
It is a Tiger Reserve.

<br /> <br />
<b>7. Yordi-Rabe Wildlife Sanctuary: </b> Located in West Siang District.


<br />
 <br />

 <b>8. Kane Wildlife Sanctuary: </b>Located in West Siang District.
 This is the smallest Wildlife Sanctuary in Arunachal Pradesh. 


 <br />
 <br />

 <b>9. Mehao Wildlife Sanctuary: </b>Located in Dibang Valley District.


 <br />
 <br />


 <b>10. Pakke Wildlife Sanctuary: </b> Located in Pakke Kessang District.
Earlier it was known as Pakhui Wildlife Sanctuary and was renamed as Pakke Wildlife Sanctuary on April 2001.
It is a Tiger Reserve.


 <br />
 <br />

 <b>11. Tale Valley Wildlife Sanctuary: </b>Located in Lower Subansiri District.

  </div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="16">
<Accordion.Header> <b className='accord-head'>17. Minerals Found in Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">

<b>1. Coal: </b>Locations where the mineral is found: <br />
1. Namchik-Namphuk (Kharsang, Changlang). <br />
2. Miao Bum (Changlang). <br />
3. Balukpong (West Kameng). <br />
4. Yomcha, Laggi and ganlin (West Siang). <br />
5. Geka (Upper Suansiri).


<br /><br />
<br />
<b>2. Dolomite: </b> Locations where the mineral is found: Rupa, Kaspi and Jamiri (west kameng).


<br /><br />
<b>3. Ferro- Silicon Quartz: </b> Locations where the mineral is found: Kalaktang (West Kameng).


<br /><br />


<b>4. Fuller’s Earth:</b> Locations where the mineral is found: Tirap

<br /><br />
<br />

<b>5. Graphite: </b> Locations where the mineral is found: <br />
1. Taliha (Upper Subansiri). <br />
2. Bopi (Kamle). <br />
3. Khetabari, Yazali (Lower Subansiri). <br />
4. Bame (West Siang). <br /> <br />

<b>NOTE: </b> Arunachal Pradesh has 35% of India’s total Graphite reserves i.e the highest in the country.

<br /><br />
<br />
<b>6. Lead & Zinc: </b> Locations where the mineral is found: <br />
1. Shergaon, Rupa (West Kameng). <br />
2. Yinkiniong (Upper Siang). <br />
3. Taliha (Upper Subansiri). <br />
4. Chirangla (Lohit). 


<br /><br />
<br />
<b>7. Limestone: </b>Locations where the mineral is found: <br />
1. Menga (Upper Subansiri). <br />
2. Pangin (Siang). <br />
3. Hunli (Dibang Valley). <br />
4. Tidding (Anjaw). <br />
5. Denin (Lohit).


<br /><br />
<br />

<b>8. Marble: </b>Locations where the mineral is found: <br />
1. Tezu, Dora (Lohit). <br />
2. Pyuli, Hunli (Dibang Valley).

<br /><br />
<br />
<b>9. Oil & Natural Gas: </b>Locations where the mineral is found: Kharsang, Diyun (Changlang).


<br /><br />
<br />

<b>10. Radioactive Minerals (Uranium & Thorium): </b>Locations where the mineral is found: Tai, Kardo, Bodak & Kigum (West Siang).

<br /><br />
  </div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="17">
<Accordion.Header> <b className='accord-head'>18. Books and Authors: Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
    .
  </div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="18">
<Accordion.Header> <b className='accord-head'>19. First in/from Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<h3 className='topic'>Political Field</h3>

<ul>
  <li>First Commissioner of NEFA:<b> Col. P. N. Luthra (1957).</b></li>
  <li>First chairman of agency council:<b> B. K. Nehru (1969-71).</b></li>
  <li>First Chief Commissioner of NEFA and Chairman of Pradesh Council:<b> K.A.A Raja (20th Jan 1972).</b></li>
  <li>First lieutenant governor of Arunachal Pradesh:<b> K. A. A. Raja (1975).</b></li>
  <li>First Governor of Arunachal Pradesh:<b> B. N. Singh (20th Feb 1987).</b></li>
  <li>First Chief Councilor of Arunachal Pradesh:<b> P. K. Thungon (1972).</b></li>
  <li>First Chief Minister of Arunachal Pradesh (UT):<b> P. K. Thungon (1972).</b></li>
  <li>First Chief Minister of Arunachal Pradesh (state): <b>Gegong Apang (1987).</b></li>
  <li>First Protem speaker (provisional legislative speaker):<b> Gora Pertin (1975).</b></li>
  <br />
  <li>First speaker of legislative assembly (UT): <b>Padi Yube.</b></li>
  <li>First speaker of legislative assembly (state): <b>T. L. Rajkumar.</b></li>
  <li>First M.P. of Lok Sabha (nominated from NEFA):<b> C. K. Gohain.</b></li>
  <li>First M.P. of Lok Sabha (elected from NEFA):<b> C. K. Gohain (1970).</b></li>
  <li>First M.P. of Lok Sabha (elected from UT): <b>R. K. Khirme</b> (1977; unopposed).</li>
  <li>First M.P. of Lok Sabha (elected from state):<b> Bakin Pertin (1980).</b></li>
  <li>First M.P. Rajya Sabha (UT):<b> Todak Basar (1972).</b></li>
  <li>First M.P. of Rajya Sabha (State): <b>Omem Moyong Deori (1984).</b></li>
  <br />
  <li>First Minister of Arunachal Pradesh in the Union Cabinet: <b>Daying Erring</b> (deputy minister of food and agriculture, 1964).</li>
  <li>First Union Deputy Minister: <b>Daying Erring (1967).</b></li>
  <li>First Union Minister of the state of Arunachal Pradesh:<b> P. K. Thungon (1991)</b>.</li>
  <li>First Parliamentary Secretary of Arunachal Pradesh to Prime minister of India:<b> Daying Erring (1962).</b></li>
  <li>First Deputy Chief Minister of Arunachal Pradesh:<b> Kameng Dolo.</b></li>
  <br /><br />
<br />
  <li>First Assembly Election: <b>25th Feb, 1978.</b></li>
  <li>First Panchayati Raj System (introduced):<b> 2nd Oct, 1960.</b></li>
  <li>First Panchayati Raj System (election):<b> 1969.</b></li>
  <li>First School:<b> School in Pasighat on self help basis (1920).</b></li>
  <li>First Lower Primary School:<b> Pasighat (1947).</b></li>
  <li>First President Rule in the state:<b> 3rd Nov, 1979: 18th Jan 1980.</b></li>
  </ul>
<br />
<br />

               <h3 className='topic'>Services and others</h3>
<ul>
  <li>First Indian Administration Service (IAS): <b>Jomin Tayeng.</b> </li>
  <li>First Indian Police Service (IPS): <b>Robin Hibu.</b></li>
  <li>First teacher: <b>Tosing Ering.</b></li>
  <li>First Doctor: <b>Dr. Odang Lego.</b></li>
  <li>First Person to Climb Mount Everest: <b>Tapi Mra.</b></li>
  <br />
  <li>First APCS officer: <b>Ganesh Koyu (1981),</b> nominated to IAS in 1991.</li>
  <li>First IGP (UT) of Arunachal Pradesh: <b>K. P. Srivastav.</b></li>
  <li>First IGP (state) of Arunachal Pradesh: <b>PS Bewa, IPS.</b></li>
  <li>First IES (Indian Engineering Service): <b>Er. Renya Ete.</b></li>
  <li>First commercial pilot license holder: <b>Anupam Tangu (1995).</b></li>
  <br />
  <li>First Indian Airforce Officer (IAF): <b>Kuru Hassang.</b></li>
  <li>First Indian Army Officer: <b>Col. Rimmo Karbak.</b></li>
  <li>First Indian Navy Officer: <b>Capt. Taru Tallo.</b></li>
  <li>First Advocate general: <b>A. M. Mazum Dar.</b></li>
  <li>First Chief Secretary of (UT) Arunachal Pradesh: <b>R. Yusuf Ali (IAS).</b></li>
  <li>First Chief Secretary of (state) Arunachal Pradesh: <b>V. K. Kapoor (IAS).</b></li>
  <li>First (Arunachalee) Chief Secretary of Arunachal Pradesh: <b>Matin Dai (IAS).</b></li>
  <br />
  <li>First chairman of APPSC of Arunachal Pradesh: <b>T. S. Deori (IAS).</b></li>
  <li>First Chief Engineer: <b>Bharat Megu(B.E.).</b></li>
  <li>First Female Teacher: <b>Late Telosai Mele (1956).</b></li>
  <li>First Female to receive Bachelors of Arts (BA) degree: <b>Bormoti Dai (1963).</b></li>
  <li>First Female to receive Bachelor of Architecture (B. Arch) degree:<b> Bipilu Chai Tawsik (1990).</b></li>
  <li>First Female to receive Master of Arts (MA) degree:<b> Khelna Namchoom (1971).</b></li>

  <br />
  <li>First person to receive Doctor of Philosophy (Ph.d) degree:<b> Dr. C. M. Manpoong.</b></li>
  <li>First Female to receive Doctor of Philosophy (Ph.d) degree:<b> Dr. Tumter Lollen (2001).</b></li>
  <br />
  <li>First Female to receive Bachelor of Medicine, Bachelor of Surgery (MBBS) degree:<b> Dr. B. Namchoom.</b></li>
  <li>First Female Indian Forest Service (IFS):<b> Koj Rinya.</b></li>
  <li>First Female Indian Administrative Service:<b> Indira Mallo (1999).</b></li>
  <li>First Female nominated member of legislative assembly (MLA):<b> Sibu Kai (1978).</b></li>
  <li>First Female elected member of legislative assembly (MLA):<b> Nyari Welly (1980).</b></li>
  <li>First Female cabinet minister of Arunachal Pradesh:<b> Komali Mossang (1990).</b></li>
  <br />
  <li>First Female member of parliament (Rajya Sabha):<b> Late Omem Moyong Deori (1984 – 1990).</b></li>
  <li>First Female to climb Mount Everest:<b> Tine Mena (9 May, 2011).</b></li>
  <li>First Female to climb Mount Everest three times:<b> Anshu Jamsenpa</b> (1st and 2nd conquest between 12 – 21 May 2011 and 3rd on 8th May, 2013).</li>
  <li>First chairperson of Arunachal Pradesh State Commission for women:<b> Jarjum Ete</b> (January 2005 – March 2008).</li>
</ul>


<h3 className='topic'>Art/Literature and institutions of Arunachal Pradesh: </h3>

<ul>
  <li>First TV station in Arunachal Pradesh: <b> Itanagar.</b> </li>
  <li>First TV serial of Arunachal Pradesh: <b> Knayar Mulya by Lummar Dai.</b> </li>
  <li>First Hindi feature film: <b> Mera Dharam Meri Maa (1976) directed by Dr. Bhupen Hazarika.</b> </li>
  <li>First journal: <b> NEFA information.</b> </li>
  <li>First English daily: <b>The Echo of Arunachal Pradesh.</b> </li>
  <li>First magazine: <b>The Hornbill (1964).</b> </li>
  <li>First editor of a newspaper: <b>Late Talom Rukbo (1988).</b> </li>
  <li>First writer of a book in Hindi: <b> Jumsi Siram.</b> </li>
  <li>First Padmashree Awardee: <b> Late Omem Moyong Deori (1984).</b> </li>
  <li>First AIR (all India radio) station: <b> Pasighat.</b> </li>
  <li>First University: <b>Arunachal University ( 4th February , 1984).</b> </li>
  <li>First vice chancellor of Arunachal University: <b> Prof. C. L. Anand.</b> </li>
  <li>First Arunachalee vice chancellor of Arunachal University: <b>Tamo Mibang.</b> </li>
  <li>First technical institute: <b> NERIST, Nirjuli (4th March, 1982).</b> </li>
  <li>First degree college: <b> Jawarlal Nehru College (JNC), Pasighat (1964).</b> </li>
  <li>First institute to provide MBBS course: <b>Tomo Riba Institute of Health and Medical Sciences (2018-2019 academic year)</b> .</li>
</ul>

  </div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="20">
<Accordion.Header> <b className='accord-head'>20. Census Data (Reports-2011) of Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
<ul>
<li><b>Smallest and Largest District by area:</b></li>
(i). Largest District: Dibang Valley (9126 sq. Km). <br />
(ii). Smallest District: Tawang (2085 sq. Km). <br />  <br />

<li><b>Literacy Rate (in percentage): 65.38%</b></li>
(i). Highest literate district in the state: Papum Pare (79.5 %). <br />
(ii). Least literate district in the state: Kurung Kumey (48.75 %).
<br />  <br />

<li><b>Population Count: 13,82,611</b></li>
(i). District with highest population: Papum Pare (1,76,385). <br />
(ii). District with least population: Dibang Valley (7948). <br />  <br />

<li><b>Population Density: 17/km2</b></li>
(i). District with highest population density: Papum Pare (51 person per sq. Km). <br />
(ii). District with least population density: Dibang Valley (1 person per sq. Km). <br />  <br />

<li><b>Decadal Growth Rate of Population:</b></li> 
(i). District with highest decadal growth rate: Kurung Kumey (111.01 %) <br />
(ii). District with least decadal growth rate: Upper Siang (5.77 %) <br />  <br />

<li><b>Sex Ratio: 938/1000</b></li> 
(i). District with highest sex ratio: Kurung Kumey ( 1032/1000). <br />
(ii). District with least sex ratio: Tawang (714/1000). <br />  <br />


<li><b>Child Sex Ratio: 972/1000</b></li> 
(i). District with highest child sex ratio: Tawang (1004/1000) <br />
(ii). District with least child sex ratio: Dibang Valley (830/1000) <br /> <br />


<li><b> The population wise distribution in percentage for religions in Arunachal Pradesh are given below:</b></li>

(i). Christian: 30.26% <br />
(ii). Hindu: 29.04% <br />
(iii). Donyi Polo (+ others): 26.2% <br />
(iv). Buddhist: 11.76% <br />
(v). Muslim: 1.9% <br />
(vi). Sikh: 0.1% <br />
(vii). Jain: less than 0.1% <br />
</ul>















</div>
</Accordion.Body>
</Accordion.Item>


<br />
{/* <Accordion.Item eventKey="19">
<Accordion.Header> <b className='accord-head'>21. Important Facts about Arunachal Pradesh</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
    .
  </div>
</Accordion.Body>
</Accordion.Item> */}



</Accordion>

  </div>

  <hr className="separator"/>


                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Societies / Forums in Arunachal </h3>

  <ol>
  <li><Link className={"link-styles"} to="">Nyishi Elite Society </Link></li>
  <li><Link className={"link-styles"} to="">Adi Bane Kebang </Link></li>
  <li><Link className={"link-styles"} to="">Galo Welfare Society </Link></li>
  <li><Link className={"link-styles"} to="">Tagin Cultural Society </Link></li>
  <li><Link className={"link-styles"} to="">Mishmi Welfare Society </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>

<h3 className='topic'>NGOs and Social Activities </h3>
   {/* <br /> */}
   <ol>
  <li><Link className={"link-styles"} to="">Deepak Nabam Living Home, Itanagar </Link></li>
  <li><Link className={"link-styles"} to="">Oju Welfare Association, Naharlagun </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Helping Hand (IPS Robin Hibu) </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Important Personalities of Arunachal</h3>
  <ol>
  <li><Link className={"link-styles"} to="/Politicalpersonalities">Political Personalities </Link></li>
  <li><Link className={"link-styles"} to="/Businesstycoons">Business Tycoons of Arunachal </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Socialworkers">Social Worker / Activists </Link></li>
  <li><Link className={"link-styles"} to="/Sportspersonalities">Sports Personalities </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Fieldspecialist">Field Specialists</Link></li>
  <li><Link className={"link-styles"} to="/Emergingyouths">Emerging Youths in Arunachal </Link></li>
  <br />

  {/* <li><Link className={"link-styles"} to="/Eminentpersonalityofarunachal">Eminent Personalities of Arunachal</Link> </li>
  <br /> */}
{/* 
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>


</div>
</div>

<hr className="separator" />

<div className="content-box">
  <br />
    <h3 className='topic' style={{color: "green"}}>Important Updates !</h3>
     <br />
<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>

    
  
    <WhatsAppShareButton/>
    <hr className="separator"/>

    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>
    <Outlet />
    </>
  )
}

export default Arunachalgk



