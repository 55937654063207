import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Formathealth({ }) {
  return (

    <>


      <Helmet>
        <title>Business by GumBaja</title>
        <meta name="description" content="Page description" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Health Format </b></h3>

<hr className="separator" />


      <div className="content-box">
        <br />
        The information provided on www.gumbaja.com is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. <br />
        In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of www.gumbaja.com. <br />
        Through www.gumbaja.com, you can link to other websites that are not under the control of www.gumbaja.com. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
        Every effort is made to keep www.gumbaja.com up and running smoothly. <br />
        However, www.gumbaja.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
        <br /><br />

      
        <Accordion defaultActiveKey={['0']} alwaysOpen className="accordianbbox">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="acoordian-head"><b>Main Head 1</b></Accordion.Header>
        <Accordion.Body className="accordian-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <br />



      <Accordion.Item eventKey="1">
        <Accordion.Header className="acoordian-head"> <b>Main Head 1</b></Accordion.Header>
        <Accordion.Body className= "accordian-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

<br />

      <Accordion.Item eventKey="2">
        <Accordion.Header className="acoordian-head"> <b>Main Head 3</b></Accordion.Header>
        <Accordion.Body className= "accordian-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

        </div>

      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Formathealth



