import React, { useState } from 'react';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import Whatsappchat from '../component/Whatsappchat';
import Call from '../common/Call';

const About = () => {

   //  WhatsApp for Others
   const [name, setName] = useState('');
   const handleSend = () => {
     const whatsappUrl = `https://api.whatsapp.com/send?phone=+917005818075&text=${encodeURIComponent(`Kindly Type Your Name: ${name}`)}`;
     window.open(whatsappUrl, '_blank');
   };
  return (

   <>
<Helmet>
  <title>About: Digital Arunachal </title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

<div className="content-box">

<h3 style={{
      textAlign: 'center',
}
 
}><b>About Us: Digital Arunachal!</b></h3>  <br />

( Digital Arunachal website is an integrated digital platform run and operated by GumBaja )
<br />

<b>Digital Services for:</b> <br />
<ul>
   <li>Ease of Living</li>
   <li>Informed Citizenry</li>
   <li>Youth Empowerment</li>
   <li>Better Arunachal</li>
</ul>






<b>Following are the services provided by the www.digitalarunachal.com:</b>
<ol>
   <li>Arunachal GK</li>
   <li>Job Updates</li>
   <li>Promotion</li>
   <li>Book Tickets</li>
   <li>Important Information</li>
   <li>Contact Numbers of Important Services</li>
   <li>Promote Tourrism</li>
   <li>Support Tourists</li>
   <li>Study Materials</li>
   <li>Many Services Details</li>
   <li>Many More</li>
</ol>



 
</div>







<div className="content-box">

<h3 style={{
      textAlign: 'center',
}
 
}><b>Contact Us</b></h3>  <br />

                 {/* WhatsApp Chat for Others*/}
{/* <div className="autobox">
      <button className='whatsappchat' onClick={handleSend}>Send WhatsApp</button>
    </div> */}


<b>
<li>Follow Us on: <a href="https://whatsapp.com/channel/0029ValQ8aOFsn0WF7owXw1J">WhatsApp Channel </a></li>
<li>Join Us on: <a href="https://chat.whatsapp.com/EkJ51IciRIZCP7frXbUTsq">WhatsApp Group</a></li>


<li>Page: <a href="https://www.facebook.com/digitalarunachal23">Facebook</a></li>

<li>Mobile: 07005369425 </li>
<br />

</b>
<Call/>


 <br />
<Whatsappchat/>
 
</div>
   
   <Footer/>
   </>
)
}

export default About
