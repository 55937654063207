import React from 'react';
import './Health.css';
import Footer from '../pages/Footer'
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import { Helmet } from 'react-helmet';
// import Healthslides from '../slider/Healthslides'
import Healthslider from './healthslider/Healthslider';
import Accordion from 'react-bootstrap/Accordion';

const Health = () => {
  return (
    <>

<Helmet>
  <title>Health Sector in Arunachal</title>
  <meta name="description" content="Health Sector in Arunachal" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

    <hr className="separator" />


    <Healthslider/>

<br/>
    <hr className="separator" />
<h2 className="latest-post" style={{color: "green"}}> <b>Health Sector in Arunachal </b> </h2>

<hr className="separator" />

    <div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Hospital in Arunachal</h3>
 
  <ol>
  <Link  className= 'topic' style={{color: "green"}} to="/health">Ambulance Contacts: <span style={{color: "blue"}}>Click Here</span></Link>
<br />
    <li><Link className={"link-styles"} to="/health">TRIHMS, Naharlgun</Link> </li>
    <li><Link className={"link-styles"} to="/health">RK Mission Hospital, Itanagar </Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/health">Heema Hospital, Itanagar</Link></li>
    <li><Link className={"link-styles"} to="/health">Samaritan Hormin Hospital, Jully Road (Naharlagun)</Link></li>
    <br />
    <li><Link className={"link-styles"} to="/health">Niba Hospital, Papu Nallah (Naharlagun)</Link></li>
    <li><Link className={"link-styles"} to="/health">Emmanuel BTM Hospital, Papu Nallah (Naharlagun)</Link></li>
    <br />
    <li><Link className={"link-styles"} to="/health">Gyati Takka General Hospital, Ziro</Link></li>
    <li><Link className={"link-styles"} to="/health">Bakin Pertin General Hospital, Pasighat</Link> </li>
    <br />
    <li><Link className={"link-styles"} to="/health">General Hospital, Aalo</Link></li>
    {/* <li><Link className={"link-styles"} to="/health"></Link></li>
    <li><Link className={"link-styles"} to="/health"></Link></li> */}
    <br />
    <h3 className='topic'><Link to="/Cmaayandhealthguide"> = CMAAY and Health Guides</Link></h3>
    
  </ol>
  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>


                            {/* Private Clinic and HS */}

<div className="sbox1 part2">
<h3 className='topic'>Private Clinic and Health Services</h3>
<ol>
  <li><Link className={"link-styles"} to="/Bkdentalacare">B K Dental Care, Naharlagun </Link></li>

<br />
  <h3 className='topic' style={{color: "green"}}>Coming Soon</h3>

  <li><Link className={"link-styles"} to="/health">Capital Dental Clinic, ICR</Link></li>
  <br />
  <li><Link className={"link-styles"} to="/health">Drishtidaan with Olley Opticals & Drugs, Naharlagun</Link></li>

  <li><Link className={"link-styles"} to="/health">Abotani Dental Care </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/health">Ganga Dental Care </Link></li>
  <li><Link className={"link-styles"} to="/health">Loku Dental Clinic </Link></li>
  <li><Link className={"link-styles"} to="/health">Dr. Dora's Dental Clinic and Orthodontric Centre</Link></li>
  <li><Link className={"link-styles"} to="/health"> </Link></li>
  


  {/* <li><Link className={"link-styles"} to="/health"> </Link></li>
  <li><Link className={"link-styles"} to="/health"> </Link></li>
  <li><Link className={"link-styles"} to="/health"> </Link></li>
   */}
</ol>



{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>




<br />
<hr className="separator" />
<br />  

                                                   {/* Doctors */}
<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>Top and Specialist Doctors of Arunachal</h3>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Orthopaedics (Musculoskeletal system: Bones): (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Paediatrics (Children related) </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. Gynaecology (Pregnancy related) </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header> <b className='accord-head'>4. General medicine </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header> <b className='accord-head'>5. Dermatology (Skin related) </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header> <b className='accord-head'>6. ENT (Ear Nose Throat) </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>7. Neurologist  </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>8. Cardiothoracic Surgery (Heart, Lungs) </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>9. General surgery  </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. Onchologist </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. Ophthalmology (Eye) </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. Ayush (Ayurvedic, Homeopathy, etc) </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


            
      <br />
      <Accordion.Item eventKey="12">
        <Accordion.Header> <b className='accord-head'>13. Urology </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


            
      <br />
      <Accordion.Item eventKey="13">
        <Accordion.Header> <b className='accord-head'>14. Dentist</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>




<br />
<hr className="separator" />
<br />

                                 {/* Health Issues in Arunachal */}
<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>Issues of Health Sector in Arunachal?</h3>
<Accordion>
  <Accordion.Item eventKey="0">
    <Accordion.Header> <b className='accord-head'> 1. : (Click Here) </b></Accordion.Header>
    <Accordion.Body>
      <div className="accord-box">
        1.
      </div>
    </Accordion.Body>
  </Accordion.Item>
  <br />


  <Accordion.Item eventKey="1">
    <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
    <Accordion.Body>
    <div className="accord-box">
        .
      </div>
    </Accordion.Body>
  </Accordion.Item>


  <br />
  <Accordion.Item eventKey="2">
    <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
    <Accordion.Body> 
    <div className="accord-box">
        .
      </div>
    </Accordion.Body>
  </Accordion.Item>



  <br />

</Accordion>

  </div>



<hr className="separator" />
    <WhatsAppShareButton/>
    <hr className="separator" />



    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>
    <Outlet />
    </>
  )
}

export default Health
