import React from 'react';
import './Realestate.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import { Helmet } from 'react-helmet';
// import Transportslider from './Transportslider'
// import Transportbox from './Transportbox'
import Accordion from 'react-bootstrap/Accordion';
import Realestateslider from './Realestateslider';



const Realestateinarunachal = () => {
  return (
    <>
<Helmet>
  <title>Real Estate in Arunachal</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

<hr className="separator"/>
<Realestateslider/>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Real Estate in Arunachal </b> </h2>

<hr className="separator" />
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Rent: House, Shops, PG</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}

</ol>
<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Property Buy / Sale </h3>
<ol>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}

</ol>

<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

</div>
</div>
<br />
<hr className="separator" />
<br />

<div className="content-box">

    <h3 className='topic' style={{color: "green"}}>Important Notes!</h3>

    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>




<hr className="separator" />
    
  
    <WhatsAppShareButton/>
    <hr className="separator" />
   

   
    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>
    <Outlet />
    </>
  )
}

export default Realestateinarunachal

