import React from 'react';
import './Politics.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
// import Jobslider from './jobslider/Jobslider';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';



const Studentsandyouthpoliticsinarunachal = () => {
  return (
    <>
<Helmet>
  <title>Arunachal Jobs Update</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


   {/* <Jobslider/> */}
    <hr className="separator"/> 
<h2 className="latest-post" style={{color: "green"}}> <b>Students' Union and Youths' Politics in Arunachal</b> </h2>

<hr className="separator" />




<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Students' Union</h3>
  
  <ol>
    <li><Link className={"link-styles"} to="">All Arunachal Pradesh Students' Union (AAPSU)</Link></li>
    </ol>

    <ol>
    <br />
    <h3 className='topic' style={{color: "green"}}>District Students' Union</h3>
    <li><Link className={"link-styles"} to="">AKDSU</Link></li>
    {/* <li><Link className={"link-styles"} to=""> </Link></li> */}
  </ol>
<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>Community Based </h3>
<ol>
  <li><Link className={"link-styles"} to="">All Nyishi Students' Union (ANSU) </Link></li>

  <li><Link className={"link-styles"} to="">Galo Students' Union (GSU)</Link> </li>
  <br />
  <li><Link className={"link-styles"} to="">Adi Students' Union (AdiSU)  </Link></li>
  <li><Link className={"link-styles"} to="">Apatani Students' Union   </Link></li>
  <br /> 
  <li><Link className={"link-styles"} to="">Tagin Students' Union</Link> <br /></li>

  <br />
  <li><Link className={"link-styles"} to="">All Nyishi Youth Association (ANYA)</Link> <br /></li>
  {/* <li><Link className={"link-styles"} to=""></Link> <br /></li> */}
</ol>




</div>
</div>




<hr className="separator" />


<div className="content-box">
  <br />
    <h3 className='topic' style={{color: "green"}}>Important Role of Students' Union !</h3>
     <br />
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>


<hr className="separator" />
    
  
    <WhatsAppShareButton/>
    <hr className="separator" />


    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
      
    <Footer/>

    <Outlet />
    </>
  )
}

export default Studentsandyouthpoliticsinarunachal;

