
import React, { useState } from 'react';
import './Personalities.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Businesstycoons({ }) {
  return (
<>


<Helmet>
  <title>Businesstycoons of Arunachal</title>
  <meta name="description" content="Businesstycoons of Arunachal Pradesh" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Business Tycoons of Arunachal </b></h3>

<hr className="separator" />




<div className="content-box">
        <br />
     
        <br />
 

<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. ... (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2.</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>5. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>6. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>7. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>8. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>9. </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="12">
        <Accordion.Header> <b className='accord-head'>13. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="13">
        <Accordion.Header> <b className='accord-head'>14. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="14">
        <Accordion.Header> <b className='accord-head'>15. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="15">
        <Accordion.Header> <b className='accord-head'>16. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="16">
        <Accordion.Header> <b className='accord-head'>17. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="17">
        <Accordion.Header> <b className='accord-head'>18. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="18">
        <Accordion.Header> <b className='accord-head'>19. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="19">
        <Accordion.Header> <b className='accord-head'>20. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="20">
        <Accordion.Header> <b className='accord-head'>21. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="21">
        <Accordion.Header> <b className='accord-head'>22. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="22">
        <Accordion.Header> <b className='accord-head'>23. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="23">
        <Accordion.Header> <b className='accord-head'>24. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="24">
        <Accordion.Header> <b className='accord-head'>25.</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />




      <Accordion.Item eventKey="25">
        <Accordion.Header> <b className='accord-head'>26. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />



      <Accordion.Item eventKey="26">
        <Accordion.Header> <b className='accord-head'>27. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      <br />

</Accordion>
</div>


<hr className="separator" />

<WhatsAppShareButton />

<hr className="separator" />


<div className="relatedposts">
<h3>Related Posts</h3>
<ol>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
</ol>
</div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Businesstycoons;



