import React, { useState } from 'react';
import './Politics.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
// import Healthformat2slides from './Healthformat2slides';
// import Healthformat2slides from '../health/Healthformat2slides';
import Govtslider from './politicsslider/Govtslider';




// npm install react-helmet

function Govtofarunachalpradesh({ }) {
  return (

    <>


      <Helmet>
        <title>Health in Arunachal</title>
        <meta name="description" content="Page description" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>




<hr className="separator"/>

<Govtslider/>

<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Govt. of Arunachal</b></h3>

<hr className="separator" />


<div className="Mainbox1">
<div className="sbox1">  
  <h3 className='topic'>Govt. of Arunachal</h3>
<ol>

  <li><Link className={"link-styles"} to="/Mpandmlaofarunachal"> MPs and MLAs of Arunachal (2024-2029)</Link></li>
  <li><Link className={"link-styles"} to=" ">Panchayat in Arunachal </Link></li>


  {/* <br /> */}
  {/* <li><Link className={"link-styles"} to="/">Economic Issues in Arunachal Pradesh</Link></li>
  <li><Link className={"link-styles"} to="/">Serious Issues in Arunachal</Link></li> */}




  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}

  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}


  

</ol>

  {/* <div className="btn-group">
  <button><Link className={"link-styles"} to="/Business">More </Link> </button>
</div> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'>Govt. Schemes and Policies </h3>
<ol>


  <li><Link className={"link-styles"} to="/"> </Link> </li>
  <li><Link className={"link-styles"} to="/"></Link></li>
</ol>

 <br />

{/* <div className="btn-group">
<button><Link className={"link-styles"} to="/Arunachalgk">More </Link> </button>
</div> */}

</div>
</div>

<br />


<hr className="separator" />

<br />

<div className="content-box">
  <br />
    <h3 className='topic' style={{color: "green"}}>Important Updates !</h3>
    <br />

    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Govt of Arunachal Initiatives: Recent (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>





<hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Govtofarunachalpradesh




