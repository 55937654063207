// import React from 'react'
import React, { useState, useEffect } from 'react';
import './Health.css';
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Bkdentalacareslider from './healthslider/Bkdentalcareslider'
import { Helmet } from 'react-helmet';
import { Outlet, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

const Bkdentalcare = () => {

     //  WhatsApp for Others
     const [name, setName] = useState('');
     const handleSend = () => {
       const whatsappUrl = `https://api.whatsapp.com/send?phone=+918837034232&text=${encodeURIComponent(`(DA) Type Your Name: ${name}`)}`;
       window.open(whatsappUrl, '_blank');
     };

  return (
    <>

<Helmet>
  <title>BK Dental Care, Naharlagun</title>
  <meta name="description" content="BK Dental Care, Naharlagun. One of the Best Dental Clinic in Naharlagun" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

<hr className="separator"/>

    <Bkdentalacareslider/>

<br/>
    <hr className="separator"/>


{/* <h2 className="latest-post" style={{color: "green"}}> <b>  </b></h2> */}
<div className="hospitalname">
<h3 className='topic' style={{color: "green"}}>B K Dental Care, Naharlagun </h3>
</div>



<hr className="separator" />

  <div className="Mainbox1">
<div className="sbox1">
  {/* <h3 className='topic'>About Doctor</h3> */}
<div className="autobox drpic">
<img src="images/ponitbaja.jpg" alt="Image description" /> 
</div>
<br />

<div className="drname">
<h3 className='topic' style={{color: "green"}}>Dr. Ponit Baja </h3>
</div>
{/* <hr className="separator" /> */}
<br />
<div className="drdescription">
<h3 className='topic' style={{color: "red"}}>Doctor's Timing:</h3>

<ul style={{color: "green"}}>
  <li><b>Morning: 9 am - 12 pm</b></li>
  <li><b>Evening: 3 pm - 7 pm</b></li>
</ul>
</div>
 
</div>




<div className="sbox1 part2 servicebox">
<h3 className='topic'>BK Dental Services </h3>
<ol>
    <li>RCT (Root Canal Treatment) and Re-RCT</li>
    <li>Post and Core</li>
    <li>Pulpectomy</li>
    <li>Restoration Composite / GIC</li>
    <li>Temporary Restoration</li>
    <li>Extraction</li>
    <li>Scaling</li>
    <li>Bleaching</li>
    <li>Flap Surgery</li>
    <li>PFM Crown</li>
    <li>Zirconia / Lava Zirconia</li>
    <li>E-Max</li>
    <li>Metal Crown</li>
    <li>Removable partial Denture Valpast/heatcure</li>
    <li>Complete Denture</li>
    <li>MTA Apexification</li>
    <li>Metapex / Calpex</li>
    <li>Implant</li>
    <li>Curettage</li>
    <li>Flap Surgery on implant</li>
    <li>Crown Lenghthening with Osteoplasty per teeth </li>
    <li>Alveoloplasty</li>
    <li>Re-implantation of Avulsed Tooth</li>
  </ol>


</div>
</div>



<hr className="separator" />
                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Patients' Rating and Testimony</h3>
  <br />
  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='prevhead'> 1. Anung Uku (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <div className="patientreview">
          "Usually I don’t like writing reviews but this clinic is genuinely one of the best clinic that I have been to. Love the friendly environment and even price is very resonable." <br />
          <b>Anung Uku</b>
          
              </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='prevhead'>2. Jomta Dong</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <div className="patientreview">
            "Very good, humbled and experienced Doctor. Thank you." <br /> <b>Jomta Dong</b> 

            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />


      
      <Accordion.Item eventKey="3">
        <Accordion.Header> <b className='prevhead'>3. Tarun Nending </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
        <div className="patientreview">   "Very Caring dentist and a reasonable price." <br />
        <b>Tarun Nending</b> </div>
     
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='prevhead'>For More </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
        <div className="patientreview">
          Visit: <a href="https://maps.app.goo.gl/ohZuGjdRkuLeNqyz9"><b>Click</b></a> and give your valuable feedback.
              </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>






      {/* <Accordion.Item eventKey="4">
        <Accordion.Header> <b className='prevhead'>5. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


</Accordion>
{/* <Link className={"link-styles"} to="/health">9.</Link> <br /> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'>Address and Contact Details </h3>
   <br />

   <h3 className='topic'  style={{color: "green", fontSize: "18px"}}>
   <b style={{color: "", fontSize: "22px"}}>BK Dental Care </b><br />
   <br />
Near Lagun Bridge <br />
Landmark: Beside Usha Restaurant, <br />
G-Extension, <br />
Naharlagun, A.P.
    </h3>
    <br />
    <h3 className='topic' style={{color: "green", fontSize: "20px"}}>Location: <a href="https://maps.app.goo.gl/ohZuGjdRkuLeNqyz9">Click Here</a> </h3>
<br /> 

<h3 className='topic'>Contact Details </h3>

<h3 className='topic' style={{color: "green", fontSize: "20px"}}>Mobile: 08837034232</h3> <br />
<h3 className='topic' style={{color: "red", fontSize: "20px"}}>Instagram: <a href="https://www.instagram.com/b.k.dentalcare?igsh=MWV1b3ZnbHB0OXJ2OA==">Click Here</a> </h3>
<br />

                 {/* WhatsApp Chat for Others*/}
{/* <div className="autobox">
      <button className='whatsappchat' onClick={handleSend}>Send WhatsApp</button>
    </div> */}

</div>
</div>





<hr className="separator" />
  
    <WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>
    <Outlet />
    </>
  )
}

export default Bkdentalcare
