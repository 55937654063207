import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from './Footer';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import { Helmet } from 'react-helmet';
import Frontslider from '../slider/Frontslider';
import Accordion from 'react-bootstrap/Accordion';

const Home = () => {
  return (
<>
<Helmet>
  <title>Welcome to Digital Arunachal</title>
  <meta name="description" content="Welcome to Digital Arunachal. This is our efforts to make Arunachal a Digital Arunachal where all informations related to Arunachal Pradesh will be available in a single platform." />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

<hr className="separator"/>
{/* <Mainslides/> */}

{/* <Formatslider/> */}
<Frontslider/>

<hr className="separator"/>

<h2 className="latest-post">
<Link to="/Latestposts">Latest-posts</Link></h2>



<hr className="separator"/>
<div className="Mainbox1">
<div className="sbox1">  
  <h3 className='topic'>Updates: Arunachal Pradesh</h3>
<ol>
   <li><Link to="/Arunachaltrendingnews">Trending News in Arunachal</Link> </li> <br />

   <li><Link to="/Upcomingevents2024">Upcomng Events in 2024</Link></li>
   <br />
  
   <li><Link to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li> <br />
  <li><Link to="/Studentsandyouthpoliticsinarunachal">Students' Union and Youth Politics in Arunachal</Link></li> <br />


  <li><Link to="/Arunachalgk">Arunachal GK</Link></li>
  <br />
  <li><Link to="/Alldistrict">All Districts of Arunachal</Link> </li>
  <br />
  <li><Link to="/Jobupdates">Job Updates </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Importantissuesinarunachal">Important Issues in Arunachal Pradesh </Link> </li>
  <br />



</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'> Arunachal Services  </h3>
<ol> 
<li><Link className={"link-styles"} to="/Business">Business in Arunachal Pradesh</Link> </li>
<br />
<li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
<br />
<li><Link className={"link-styles"} to="/Realestateinarunachal">Real Estates Sector</Link></li>
<br />
<li><Link className={"link-styles"} to="/Arunachaltransports">Transports Services</Link> </li>
<br />
<li><Link className={"link-styles"} to="/Besthotelandrestroinarunachal">Best Hotels, Resorts, Restaurants, Cafe etc.. in Arunachal</Link></li>
<br />

<li><Link className={"link-styles"} to="/">Top APPSC/APSSB/NEET Coaching Institutes in Arunachal </Link> </li>
<br />

<li><Link className={"link-styles"} to="/Sportsinarunachal">Sports and Entertainment</Link> </li>
<br />

  <li><a href="https://gumbaja.com/Iastoppersandsyllabus">Study Materials (Syllabus: APPSCCE/IAS)</a></li>

<br />

 

  {/* <li><Link className={"link-styles"} to="/"> </Link> </li> */}
  {/* <li><Link className={"link-styles"} to="/"></Link></li> */}
</ol>

</div>
</div>

{/* 
<hr className="separator"/>

<div className="Mainbox1">
<div className="sbox1">  
  <h3 className='topic'>Arunachal Jobs & Health</h3>
<ol>
</ol>
</div>

<div className="sbox1 part2">
<h3 className='topic'> Arunachal Services  </h3>
<ol> 


</ol>

</div>
</div> */}



<br />
<hr className="separator" />
<br />

<div className="content-box">
    <h3 className='topic' style={{color: "green"}}>What is www.digitalarunchal.com?</h3>

    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />

</Accordion>

  </div>


<hr className="separator"/>



<WhatsAppShareButton/>

<hr className="separator"/>

<Footer/>
<Outlet />
</>
  )
}

export default Home
