import React, { useState } from 'react';
import './Common.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';




// npm install react-helmet

function Ansuelection2024({ }) {
  return (

    <>


      <Helmet>
        <title>ANSU Election 2024: Updates</title>
        <meta name="description" content="ANSU Election 2024" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>
<Carousel data-bs-theme="dark">


{/* Slider 1 */}
<Carousel.Item>

<div className="carouselimage">
<a href="https://www.digitalarunachal.com/">
<img
// className="d-block w-100"
src="images/amap.png"
alt="First slide"
/>
</a>

</div>

{/* <Carousel.Caption>
<h5>First slide label</h5>
<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
</Carousel.Caption> */}
</Carousel.Item>



{/* Slider 2 */}
<Carousel.Item>
<div className="carouselimage">
<Link to="/"> 
<img
src="images/digital.png"
alt="Second slide"
/>
</Link>
</div>


{/* <Carousel.Caption>
<h5>Second slide label</h5>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</Carousel.Caption> */}
</Carousel.Item>

{/* Slider 3 */}
<Carousel.Item>
<div className="carouselimage">
<img
src="images/da.jpg"
alt="Third slide"
/>
</div>
{/* <Carousel.Caption>
<h5>Third slide label</h5>
<p>
Praesent commodo cursus magna, vel scelerisque nisl consectetur.
</p>
</Carousel.Caption> */}
</Carousel.Item>

</Carousel>

<hr className="separator"/>





<h3 className="latest-post" style={{color: "green"}}> <b>ANSU Election 2024: Updates </b></h3>

<hr className="separator" />


<div className="content-box">

  <div className="accordboxtop">


  </div>
 

  

<Accordion>
<h3 className='topic'>President </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />

                   {/* Vice P */}
<h3 className='topic'>Vice-President </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />







                     {/* GS */}
<h3 className='topic'>General Secretary </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />



                       {/* AGS */}
<h3 className='topic'>AG Secretary </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />

                                  {/* Convenor */}

<h3 className='topic'>Convenor </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />



                                {/* Co-con */}
<h3 className='topic'>Co-Convenor </h3>
<hr className="separator"/>

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />




                    {/* IPR */}
<h3 className='topic'>IPR Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />



<h3 className='topic'>Asst. IPR Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />


                          {/* Finance Sec */}
<h3 className='topic'>Finance Secretary </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />




<h3 className='topic'>Asst. Finance Secretary </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />



                               {/* Sports */}
<h3 className='topic'>Games & Sports Secretary Candidates: </h3>

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<br />
<h3 className='topic'>Assistant Games & Sports Secretary Candidates: </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />


                  {/* Education Secretary */}
<h3 className='topic'>Education Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<br />
<h3 className='topic'>Assistant Education Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<hr className="separator"/>
<br />


                    {/* Social Services Secretary */}
<h3 className='topic'>Social Service & health Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />





<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<br />
<h3 className='topic'>Asst. Social Service & health Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />


             {/* Art and Culture */}
<h3 className='topic'>Art and Culture Secretary Candidates </h3>

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<h3 className='topic'>Asst. Art and Culture Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />



                      {/* Women */}
<h3 className='topic'>Secretary Women Wing Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />




<br />
<h3 className='topic'>Asst. Secretary Women Wing Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<hr className="separator"/>
<br />


<h3 className='topic'>Audit Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
{/* <br />
<h3 className='topic'>Secretary </h3> */}

<hr className="separator"/>
<br />


<h3 className='topic'>Office Secretary Candidates </h3>
<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />



<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
<br />

<h3 className='topic'>Asst. Office Secretary Candidates</h3>

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

<Accordion.Item eventKey="22">
<Accordion.Header> <b className='accord-head'> .   </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<h3 className='topic'>ABC </h3>
  <ol>
    <li></li>
    <li></li>
    <li></li>
  </ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />

</Accordion>


  </div>





<hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Ansuelection2024;



