import React, { useState } from 'react';
import './News.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Importantissuesinarunachal ({ }) {
  return (

    <>


      <Helmet>
        <title>Important Issues in Arunachal</title>
        <meta name="description" content="Important Issues in Arunachal" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Important Issues in Arunachal </b></h3>

<hr className="separator" />


{/*  Issues in News */}
<div className="content-box">
<br />
<h3 className='topic'>Some Important Issues in News</h3>
  <br />

<Accordion>
<Accordion.Item eventKey="0">
  <Accordion.Header> <b className='accord-head'> 1. Issues Offspring Issue: (Click Here) </b></Accordion.Header>
  <Accordion.Body>
    <div className="accord-box">
      <ol>
        <li></li>
      </ol>
    </div>
  </Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
  <Accordion.Header> <b className='accord-head'>2. Paper Leakages:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
  <li>APPSC </li>
  <li>APSSB</li>
  <li>Other Recruitments</li>
  <li>Backdoor Entry / Illegal Appointment:</li>
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
  <Accordion.Header> <b className='accord-head'>3. School Education Issues (Very Poor):</b></Accordion.Header>
  <Accordion.Body> 
  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="3">
  <Accordion.Header><b className='accord-head'>4. Issues in Sports Field: </b> </Accordion.Header>
  <Accordion.Body>

  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="4">
  <Accordion.Header><b className='accord-head'>5. Transport System (Issues in Public Transport):</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>

<br />
<Accordion.Item eventKey="5">
  <Accordion.Header><b className='accord-head'>6. Capital Area- Road Maintenance Issue:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="6">
  <Accordion.Header> <b className='accord-head'>7. Start up and Business Environment For Youths: </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="7">
  <Accordion.Header> <b className='accord-head'>8. Itanagar Capital Region Garbage Problem:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="8">
  <Accordion.Header> <b className='accord-head'>9.   Unemployment Issues </b></Accordion.Header>
  <Accordion.Body>

  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item>


{/* 

<br />
<Accordion.Item eventKey="9">
  <Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
      .
    </div>
  </Accordion.Body>
</Accordion.Item> */}


</Accordion>


  </div>


<hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Importantissuesinarunachal;




