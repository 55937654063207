
import React from 'react'
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Jobslider from '../jobs/Jobslider';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';




const Institutes = () => {
  return (
    <>
<Helmet>
  <title>Institutes</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

<hr className="separator"/> 

   <Jobslider/>

   {/* <Formatslider/> */}
    <hr className="separator"/> 
<h2 className="latest-post" style={{color: "green"}}> <b>Institutes</b> </h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Available Courses</h3>
  
  <ol>
    <li><Link className={"link-styles"} to=""></Link></li>
    <br />
    <li><Link className={"link-styles"} to=""></Link></li>
    {/* <li><Link className={"link-styles"} to=""> </Link></li> */}
  </ol>
<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>Available Courses </h3>
<ol>
  {/* <li><a href="https://www.gumbaja.com/Ibpspoform2024">IBPS PO Form 2024 (4455 Posts)</a></li> */}
  <br />
  <li></li>

  <li> </li>

  <br />
  {/* <li><Link className={"link-styles"} to="">  </Link></li>
  <li><Link className={"link-styles"} to="">  </Link></li>
  <br /> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li>
  <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
</ol>




</div>
</div>



<br />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Our Faculties</h3>
  
  <ol>
    <li><Link className={"link-styles"} to=""></Link></li>
    <br />
    <li><Link className={"link-styles"} to=""></Link></li>
    {/* <li><Link className={"link-styles"} to=""> </Link></li> */}
  </ol>
<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>Our Toppers </h3>
<ol>
  {/* <li><a href="https://www.gumbaja.com/Ibpspoform2024">IBPS PO Form 2024 (4455 Posts)</a></li> */}
  <br />
  <li> </li>

  <li> </li>

  <br />
  {/* <li><Link className={"link-styles"} to="">  </Link></li>
  <li><Link className={"link-styles"} to="">  </Link></li>
  <br /> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li>
  <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
</ol>




</div>
</div>

<br />




<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Students Reviews</h3>
  
  <ol>
    <li><Link className={"link-styles"} to=""></Link></li>
    <br />
    <li><Link className={"link-styles"} to=""></Link></li>
    {/* <li><Link className={"link-styles"} to=""> </Link></li> */}
  </ol>
<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>Address | Contact Details </h3>
<ol>
  <li></li>
  <br />
  <li></li>

  <li></li>

  <br />
  {/* <li><Link className={"link-styles"} to="">  </Link></li>
  <li><Link className={"link-styles"} to="">  </Link></li>
  <br /> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li>
  <li><Link className={"link-styles"} to="">Coming Soon</Link> <br /></li> */}
</ol>




</div>
</div>


<hr className="separator" />


<div className="content-box">
  <br />
    <h3 className='topic' style={{color: "green"}}> Q and A !</h3>
     <br />
<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />

</Accordion>

  </div>


<hr className="separator" />
    
  
    <WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>

    <Outlet />
    </>
  )
}

export default Institutes

