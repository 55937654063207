import React from 'react';
import './Health.css';
import Footer from '../pages/Footer'
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import { Helmet } from 'react-helmet';
// import Healthslides from '../slider/Healthslides'
import Healthslider from './healthslider/Healthslider';
import Accordion from 'react-bootstrap/Accordion';

const Cmaayandhealthguide = () => {
return (
<>

<Helmet>
<title>CMAAY and Health Guide</title>
<meta name="description" content="CMAAY and Health Guide" />
<meta property="og:title" content="Social media title" />
<meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

{/* <hr className="separator" />
<Healthslider/>

<br/>
<hr className="separator" /> */}
<hr className="separator" />
<h2 className="latest-post" style={{color: "green"}}> <b>CMAAY and Health Guide </b> </h2>
<hr className="separator" />
        {/* Health Insurance */}
              {/* Health Issues in Arunachal */}
              <div className="content-box">
<h3 className='topic' style={{color: "green"}}></h3>
<Accordion>
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. What is CMAAY and PMJAY? (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
Launched on 15th August 2018,<b> "Chief Minister Arogya Arunachal Yojana" </b>is a flagship program of the Department of Health and Family Welfare, Government of Arunachal Pradesh. <br />
 The scheme aims to accord quality and cashless healthcare services to the indigenous people of the state and government employees and reduce out-of-pocket (OOP) expenses during major illness and hospitalization, thereby envisaging universal healthcare for all sections of society. <br /> <br />


 <b>Objectives: </b>
 <ul>
  <li>To reduce the financial hardship on people in the state due to disease and hospitalization.</li>
  <li>To equitably ensure assured access to quality health care for all bonafide citizens of the state.</li>
  <li>To provide quality health care benefits for state government employees and their dependents.</li>
  <li>To make the public health system efficient, effective, and self-reliant by receipt of claim refunds into the individual government hospital accounts. </li>
  <li>To fulfill the secondary and tertiary health care gap in the state. </li>
 </ul>









</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2. Benefits </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
  <ol>
    <li>Coverage of up to ₹5,00,000 per family per year.</li>
    <li>Cashless treatments during hospitalization.</li>
    <li>Pre-existing diseases are inclusively covered.</li>
    <li>The scheme encompasses expenses for pre-hospitalization for up to 3 days.</li>
    <li>Post-hospitalization expenses for up to 10 days are covered.</li>
    <li>Hospitalization expenses include: <br />
    Registration Charges, Bed Charges (General Ward), Nursing and Boarding Charges, Surgeons, Anaesthetists, Medical Practitioners, Consultants Fees, etc..
    Anaesthesia, Blood Transfusion, Oxygen, Operating Theatre (OT) Charges, Cost of Surgical Appliances, etc., Medicines and Drugs, Cost of Prosthetic Devices, Implants, etc., Pathology and Radiology Tests: Radiology to Include but Not Be Limited to X-ray, Magnetic Resonance Imaging (MRI), Computed Tomography (CT) Scan, etc., Diagnosis and Tests, etc., Food for the Patient.

    </li>
    <li>Pre and Post-hospitalization expenses encompass costs incurred for consultation, diagnostic tests, and medicines before the patient's admission to the hospital. Additionally, it covers the cost of diagnostic tests and medicines up to 10 days post-discharge for the same ailment/surgery.</li>
    <li>All other expenses related to the patient's treatment within the hospital are also covered.</li>
  </ol>
 
  <b>NOTE:</b> In the case the applicant has employment with the state government, the insurance program’s benefits are also available to their dependents.

</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. Eligibility</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
The applicant should belong to one of the tribal communities that are recognized by the state of Arunachal Pradesh. <br />
The applicant should be legally residing in the Changlang, Lohit, or Namsai Districts of Arunachal Pradesh. <br />
The applicant should be a member of one of the backward communities. <br />
The people who are enrolled in the Arunachal Pradesh Scheduled Tribe (APST) are directly eligible. <br />
In the case the applicant has employment with the state government, the insurance program’s benefits are also available to their dependents.
</div>
</Accordion.Body>
</Accordion.Item>

<br />
<Accordion.Item eventKey="3">
<Accordion.Header> <b className='accord-head'>4. Exclusions</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
  <ul>
    <li>Conditions That Do Not Require Hospitalization.</li>
    <li>Hospitalization primarily for evaluation / diagnostic purposesAny dental treatment or surgery which is corrective, cosmetic or of aesthetic procedure, filling of cavity, root canal including wear and tear etc. unless arising from disease, illness or injury and which requires hospitalization for treatment.</li>
    <li>Congenital external diseases.</li>
    <li>Fertility related procedures, Drugs and Alcohol Induced illness Vaccination Suicide Persistent Vegetative State.</li>
    {/* <li></li>
    <li></li> */}
  </ul>




</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="4">
<Accordion.Header> <b className='accord-head'>5. Application Process</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
Step 1: Visit the Official Portal of CMAAY. <br />
Step 2: Scroll down to the bottom of the homepage. In the "Enrollment" section, click <a href="https://cmaay.com/">'Apply Online'</a>. <br />
Step 3: In the enrollment form, fill in all the mandatory fields, upload all the mandatory documents, and click "Submit". <br />
Step 4: Upon successful enrollment, the beneficiary can download the e-Card.
<br /> <br />

Check Application Status: <br />
Step 1: Visit the "Check Status" page of CMAAY. <br />
Step 2: Enter your District and your URN / Aadhaar Number. Click "Search".

</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="5">
<Accordion.Header> <b className='accord-head'>6. Documents Required</b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
  <ul>
    <li>Aadhaar Card.</li>
    <li>Birth Certificate in case of children under 5 years.</li>
    <li>Ration Card.</li>
    <li>For APST (Arunachal Pradesh Scheduled Tribe) - An APST Certificate is mandatory.
    For Non-APST - A Resident Certificate is mandatory.</li>
    <li>For Government Employees - A Government ID Card is mandatory.</li>
    <li>Permanent Land Holding Documents.</li>
    <li>Approved Enrollment Form.</li>
  </ul>
</div>
</Accordion.Body>
</Accordion.Item>

<br />

</Accordion>

</div>





<br />
<hr className="separator" />
<br />

              {/* Health Issues in Arunachal */}
<div className="content-box">
<h3 className='topic' style={{color: "green"}}>Health Guides !</h3>
<Accordion>
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. Health Awareness: (Click Here) </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
1.
</div>
</Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2.  Know Your Body & Health Issues</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. Diseases </b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
.
</div>
</Accordion.Body>
</Accordion.Item>



<br />

</Accordion>

</div>



<hr className="separator" />
<WhatsAppShareButton/>
<hr className="separator" />



<br/>
<div className="relatedposts">
<h3>Related Posts</h3>
<ol>
<li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
<li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
<li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
<li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
</ol>
</div>
<br />
<Footer/>
<Outlet />
</>
)
}

export default Cmaayandhealthguide;
