import React from 'react';
import './Transport.css';
import Footer from '../pages/Footer';
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Transportslider from './Transportslider'

import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import { Outlet, Link } from "react-router-dom";



const Arunachaltransports = () => {
  return (
    <>
<Helmet>
  <title>Transport Services in Arunachal</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>

   {/* <Transportslider/> */}

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Arunachal Transports </b> </h2>
<hr className="separator" />

<br />

<div className="transportbox">

<ol>
<h3 style={{color: "darkgreen"}}>Contact us to Book Tickets </h3>
<li><Link className={"link-styles"} to="">Train Tickets </Link> </li>
<li><Link className={"link-styles"} to="">Flight Tickets </Link> </li>
<li><Link className={"link-styles"} to="">Bus and Sumo /Winger Tickets </Link> </li>

<br /><br />

        <h5><b>Mobile: </b></h5>

</ol>


</div>
<br />



{/*  */}

<div className="content-box">
    <h3 className='topic' style={{color: "red"}}>Contact here for Transports Services</h3>

    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Book: Volvo / Bus Services in A.P. (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            <ol>
              <li>  Book APSTS (Volvo Bus) Tickets: <b><a href="https://apsts.arunachal.gov.in/" target="_blank" rel="noopener noreferrer"> Click Here</a></b> <br />
              <span><b>Contact No. 9863319884</b></span></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Tata Sumo Services: All Districts of A. P. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. Innova and Winger Services in A.P.</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />



      <h3 className='topic' style={{color: "red"}}>Town Transports Services</h3>

      <Accordion.Item eventKey="3">
        <Accordion.Header> <b className='accord-head'>4. Book Cab/Taxi: (Towns / Airport / Railway Station, etc.) in A.P.</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />



      <Accordion.Item eventKey="4">
        <Accordion.Header> <b className='accord-head'>5. Goods Transport / Carrier Vehicles in A.P. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />



      <Accordion.Item eventKey="5">
        <Accordion.Header> <b className='accord-head'>6. Book: Auto and Tempo Services in A.P. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>7. Transport Union / Associations</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />

{/* 
      <Accordion.Item eventKey="30">
        <Accordion.Header> <b className='accord-head'>8. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br /> */}

</Accordion>

  </div>

<br />
<hr className="separator" />
<br />

  <div className="content-box">
    <h3 className='topic' style={{color: "red"}}>How to Book or Avail these Services</h3>

    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1.  (Click Here) </b></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            1.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2.  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. .</b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />

</Accordion>

  </div>



<hr className="separator" />

    
  
    <WhatsAppShareButton/>
    <hr className="separator" />
  
    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
      <Outlet />
    <Footer/>
    </>
  )
}

export default Arunachaltransports
