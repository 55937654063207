import React from 'react'
const WhatsappShareButton = ({ content = window.location.href }) => {
  const encodedContent = encodeURIComponent(content);
  const whatsappUrl = `https://wa.me/?text=${encodedContent}`;

  const handleClick = () => {
    window.open(whatsappUrl, '_blank'); // Open link in a new tab
  };

  return (

    <div className='autobox'>
    <button onClick={handleClick} className="whatsapp">Share on WhatsApp</button>
    </div>
  );
};

export default WhatsappShareButton;
