import React from 'react';
import './Sports.css';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import { Helmet } from 'react-helmet';
import Sportsslider from './sportsslider/Sportsslider';

const Sportsinarunachal = () => {
  return (
    <>

<Helmet>
  <title>Education Sector of Arunachal</title>
  <meta name="description" content="Page description" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
  <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
</Helmet>
<hr className="separator"/>
  <Sportsslider/>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Sports & Entertainment in Arunachal </b> </h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Sports and Govt. of Arunachal</h3>
  {/* <br /> */}
  <ol>
  <li><Link className={"link-styles"} to="">Government of Arunachal and Sports </Link></li>
  <li><Link className={"link-styles"} to="">Sports Awards in Aruachal </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Sports Played in Arunachal </Link></li>
  <li><Link className={"link-styles"} to="">Sports Adventurism Places in Arunachal </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Mountaineers in Arunachal </Link></li>
  <li><Link className={"link-styles"} to="">Sports Personalities in Arunachal </Link></li>
  <br />
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>

<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>Sports' Places in Arunachal </h3>
{/* <br /> */}
<ol>
  <li><Link className={"link-styles"} to="">Futsal in ICR (Itanagar, Naharlagun) </Link></li>
  <li><Link className={"link-styles"} to="">Volley Ball Courts in ICR </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Badminton Courts in Arunachal </Link></li>
  <li><Link className={"link-styles"} to="">Snooker's Play Zone in Arunachal </Link></li>
  <br />
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>

</div>
</div>



<hr className="separator" />
                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Entertainment in Arunachal</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Cinema Hall (Theatre) in Arunachal </Link></li>
  <li><Link className={"link-styles"} to="">Play Zone in Arunachal (Gaming) </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Swimming Pools in Arunachal </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Sports Personality / Associations </h3>
   {/* <br /> */}
   <ol>
  <li><Link className={"link-styles"} to="">Karate </Link></li>
  <li><Link className={"link-styles"} to="">Football </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Badminton </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li> */}
</ol>

</div>
</div>


<hr className="separator" />
    
  
    <WhatsAppShareButton/>
    <hr className="separator" />



    <br/>
    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/Arunachalgk">Arunachal GK </Link></li>
          <li><Link className={"link-styles"} to="/Politicsofarunachalpradesh">Arunachal Govt. and Politics</Link></li>
          <li><Link className={"link-styles"} to="/Business">Business Services </Link></li>
          <li><Link className={"link-styles"} to="/Health">Health Sector </Link> </li>
        </ol>
      </div>
      <br />
    <Footer/>
    <Outlet />
    </>
  )
}

export default Sportsinarunachal

